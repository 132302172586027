import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Slider,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Layout from "../Components/Layout";
import Footer from "../Components/Footer";
import TourData from "../Components/RenderData/TourData";
import TourBar from "../Components/TourData/TourBar";
import ReactSlickSlider from "react-slick";
import "slick-carousel/slick/slick.css"; // Slider styles
import "slick-carousel/slick/slick-theme.css"; // Slider theme styles
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../Firebase/firebase"; // Import your Firebase config

const Tour: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [priceFilter, setPriceFilter] = useState<number[]>([0, 100000]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const toursPerPage: number = 9; // Adjust as needed

  useEffect(() => {
    const fetchImages = async () => {
      try {
        console.log("Fetching images from Firestore...");
        const imagesRef = collection(db, "mainSlider");
        const q = query(imagesRef, where("page", "==", "Tour"));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          console.log("No images found for the specified page.");
          return;
        }

        const imageUrls = querySnapshot.docs.map((doc) => doc.data().url);
        console.log("Fetched image URLs:", imageUrls);

        setImages(imageUrls);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const handlePriceFilterChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setPriceFilter(newValue as number[]);
  };
  const [showMore, setShowMore] = useState(false);
  const facilities = [
    { label: "Best Value" },
    { label: "Boutique" },
    { label: "Budget" },
    { label: "Business" },
    { label: "Charming" },
    { label: "Classic" },
    { label: "Green" },
    { label: "Luxury" },
    { label: "Mid Range" },
    { label: "Party" },
  ];

  const applyPriceFilter = () => {
    console.log("Applying price filter:", priceFilter);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    const totalTours = 100; // Total number of tours
    const totalPages = Math.ceil(totalTours / toursPerPage);
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  const visibleFacilities = showMore ? facilities : facilities.slice(0, 3);
  const settings = {
    // dots: true, // Uncomment this if you want dots to be visible
    infinite: images && images.length > 1, // Only enable infinite if there is more than one image
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Layout>
       <Box sx={{ position: "relative", overflow: "hidden" }}>
        <ReactSlickSlider {...settings}>
          {images.map((image, index) => (
            <Box
              key={index}
              component="img"
              src={image}
              alt={`Slider Image ${index}`}
              sx={{ height: "400px", width: "100%", objectFit: "cover" }}
            />
          ))}
        </ReactSlickSlider>
        <Box
        >
         <Grid
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              width: "100%", // Make sure it spans the full width of the container
              p: { lg: 5, xs: 2 },
              zIndex: 1, // Ensure it sits on top of the slider
            }}
            container>
            <Grid item xs={12}>
              <TourBar />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ m: { lg: 3, xs: 3 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <Box p={2}>
              <Box p={3} bgcolor={"#f0efed"} borderRadius={5}>
                <Typography gutterBottom variant="h6">
                  Price Filter
                </Typography>
                <Slider
                  value={priceFilter}
                  onChange={handlePriceFilterChange}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100000}
                  step={1000}
                  aria-labelledby="price-filter"
                />
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-around"}
                >
                  <Typography
                    bgcolor={"white"}
                    p={1}
                    borderRadius={3}
                    variant="body2"
                  >
                    {" "}
                    Price: {priceFilter[0]}
                  </Typography>
                  <Typography
                    bgcolor={"white"}
                    p={1}
                    borderRadius={3}
                    variant="body2"
                  >
                    Price : {priceFilter[1]}
                  </Typography>
                </Box>
                <Divider sx={{ mt: 3, mb: 3 }} />
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    onClick={applyPriceFilter}
                    sx={{ mt: 2 }}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* Facility Filters */}
            <Box p={2}>
              <Box
                p={3}
                bgcolor={"#f0efed"}
                borderRadius={5}
                display={"flex"}
                flexDirection={"column"}
              >
                <Typography variant="h6" fontWeight="bold">
                  Review Score
                </Typography>
                <FormControlLabel control={<Checkbox />} label="Excellent" />
                <FormControlLabel control={<Checkbox />} label="Very Good" />
                <FormControlLabel control={<Checkbox />} label="Average" />
                <FormControlLabel control={<Checkbox />} label="Poor" />
                <FormControlLabel control={<Checkbox />} label="Terrible" />
              </Box>
            </Box>

            <Box p={2}>
              <Box
                p={3}
                bgcolor={"#f0efed"}
                borderRadius={5}
                display={"flex"}
                flexDirection={"column"}
              >
                <Typography variant="h6" fontWeight="bold">
                  Facilities
                </Typography>

                {visibleFacilities.map((facility, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox />}
                    label={facility.label}
                  />
                ))}
                {/* Show more or show less button */}
                {!showMore ? (
                  <Button onClick={() => setShowMore(true)}>See more</Button>
                ) : (
                  <Button onClick={() => setShowMore(false)}>See less</Button>
                )}
              </Box>
            </Box>
          </Grid>

          {/* Tour Cards Section */}
          <Grid item xs={12} lg={9}>
            <Box mt={2}>
              <TourData
                currentPage={currentPage}
                itemsPerPage={toursPerPage}
                priceFilter={priceFilter}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button disabled={currentPage === 1} onClick={handlePreviousPage}>
                Previous
              </Button>
              <Button onClick={handleNextPage}>Next</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Layout>
  );
};

export default Tour;
