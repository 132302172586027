import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import HotelCard from '../Cards/HotelCard';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../Firebase/firebase'; // Make sure this path is correct

interface HotelDataProps {
    currentPage: number;
    hotelsPerPage: number;
    priceFilter : number[];
}

const HotelData: React.FC<HotelDataProps> = ({ currentPage, hotelsPerPage, priceFilter }) => {
    const [hotelData, setHotelData] = useState<any[]>([]);
    // const hotelData = [
    //     { imageSrc: require('../../Assets/Hotel/hunzaelites.png'), title: 'Hunza Elites', price: 12000, rating: 2, linkTo:'/HotelElite', days: '5 Nights' },
    //     { imageSrc: require('../../Assets/Hotel/Northhome-easilytrip-02.png'), title: 'Dirleh Hotel Skardu', price: 25000, rating: 4, linkTo:'/NorthHome' },
    //     { imageSrc: require('../../Assets/Hotel/khoj-easilytrip-03.png'), title: 'Khoj Resort Shigar', price: 48783, rating: 3, days: '', linkTo:'/KhojResortSh0igar' },
    //     { imageSrc: require('../../Assets/Hotel/Broq-easilytrip-08.png'), title: 'Broq Hotel', price: 15000, rating: 3, days: '3 Nights', linkTo:'' },
    //     { imageSrc: require('../../Assets/Hotel/Dirlleh-easilytrip-03.png'), title: 'Dirlleh Hotel', price: 18000, rating: 4, days: '4 Nights', linkTo:'/DirlehHotelSkardu' },
    //     { imageSrc: require('../../Assets/Hotel/famreehunza-easilytrio-05.png'), title: 'Famree Hunza', price: 22000, rating: 5, days: '5 Nights', linkTo:'/FamreeResortHunza' },
    //     { imageSrc: require('../../Assets/Hotel/Geyari-easilytrip-08.png'), title: 'Geyari Hotel', price: 19000, rating: 4, days: '3 Nights', linkTo:'' },
    //     { imageSrc: require('../../Assets/Hotel/Hard-rock-hunza-easilytrip-06.png'), title: 'Hard Rock Hunza', price: 30000, rating: 4, days: '4 Nights', linkTo:'/HardRockHunza' },
    //     { imageSrc: require('../../Assets/Hotel/hotel_12.jpeg'), title: 'Hotel 12', price: 17000, rating: 3, days: '2 Nights', linkTo:'' },
    //     { imageSrc: require('../../Assets/Hotel/north-palace-easilytrip-010.png'), title: 'North Palace', price: 26000, rating: 4, days: '4 Nights', linkTo:'/NorthHome' },
    //     { imageSrc: require('../../Assets/Hotel/osis-easilytrip-04.png'), title: 'Osis Hotel', price: 28000, rating: 4, days: '5 Nights', linkTo:'/OasisResorts' },
    //     { imageSrc: require('../../Assets/Hotel/Ramada_13.jpg'), title: 'Ramada Hotel', price: 32000, rating: 5, days: '6 Nights', linkTo:'' },
    //     { imageSrc: require('../../Assets/Hotel/Tibet-kachura-shangrila-easilytrip-07.png'), title: 'Tibet Kachura Shangrila', price: 38000, rating: 5, days: '7 Nights', linkTo:'/TibetHotel' },
    //     { imageSrc: require('../../Assets/Hotel/famreehunza-easilytrio-05.png'), title: 'Famree Hunza', price: 22000, rating: 5, days: '5 Nights', linkTo:'' },
    //     { imageSrc: require('../../Assets/Hotel/Geyari-easilytrip-08.png'), title: 'Geyari Hotel', price: 19000, rating: 4, days: '3 Nights', linkTo:'' },
    //     { imageSrc: require('../../Assets/Hotel/Hard-rock-hunza-easilytrip-06.png'), title: 'Hard Rock Hunza', price: 30000, rating: 4, days: '4 Nights', linkTo:'' },
    // ];

    useEffect(() => {
        const fetchHotels = async () => {
          const querySnapshot = await getDocs(collection(firestore, 'hotels'));
          const hotelsData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setHotelData(hotelsData);
        };
        
        fetchHotels();
      }, []);
    
    const filteredHotels = hotelData.filter(hotel => hotel.price >= priceFilter[0] && hotel.price <= priceFilter[1]);

    const startIndex = (currentPage - 1) * hotelsPerPage;
    const endIndex = startIndex + hotelsPerPage;
    const paginatedHotels = filteredHotels.slice(startIndex, endIndex);

    return (
        <Grid container spacing={1} sx={{ display: 'flex', flexDirection: 'row' }}>
            {paginatedHotels.map((hotel, index) => (
                <Grid item key={index} lg={3} md={6}>
                    <HotelCard
                        imageSrc={hotel.images[0]}
                        title={hotel.name}
                        price={hotel.price.toString()} // Convert price to string for HotelCard
                        days={hotel.duration}
                        rating={hotel.rating} 
                        linkTo={`/HotelDetail/${hotel.id}`} 
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default HotelData;
