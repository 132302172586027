import React from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox } from '@mui/material';
import Layout from '../../Components/Layout';
import Footer from '../../Components/Footer';


import backgroundImage from '../../Assets/All/Tour.jpg';
import Listitem from './ListItem';

const Terms: React.FC = () => {
    return (
        <Layout>
            <Box>
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: '60vh',
                    }}
                >
                </Box>

                <Box sx={{ m: { lg: 10, xs: 3 } }}>
                    <Typography fontSize={28} mb={1} fontWeight='bold'>Terms and Conditions  – TourEase.com</Typography>
                    <Typography variant='body1' >Greetings from Easilytrip.com! (referred to as “we,” “us,” or “our”). Please carefully read the following terms and conditions that govern your use of our website and services. By accessing or using HotelsinSkardu.com, you agree to comply with and be bound by these terms. If you do not agree with any part of these terms, you may not use our website.</Typography>

                    <Box sx={{  mt: 5 }}>
                         
                    <ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Hotel and Tour Section:" nestedText={' TourEase.com serves as a platform where local hotels and tour operators can register their properties. It is important to note that these entities register independently on our website. b. We do not assume responsibility for any mismanagement or issues arising from the day-to-day operations of the registered hotels. Any disputes or concerns related to the hotel’s management should be addressed directly with the respective property.'} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Insurance and Liability:" nestedText={' TourEase.com does not provide any form of insurance coverage for accidents, injuries, or damages occurring during your stay at a registered hotel or while participating in a tour. b. We are not responsible for any natural disasters, including but not limited to earthquakes, floods, or any other acts of nature. Guests and users are advised to take appropriate precautions and assess the risks associated with their travel plans.'} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Acceptance of Terms and Conditions:" nestedText={' By using TourEase.com and ticking the acceptance box for the terms and conditions during the booking process, you acknowledge and agree to all the terms outlined herein. b. It is your responsibility to review these terms periodically for any changes or updates. Continued use of the website after any modifications constitutes acceptance of those changes.'} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="User Responsibilities:" nestedText={' You agree to provide accurate and up-to-date information when using TourEase.com. b. You are responsible for maintaining the confidentiality of your account information and password. c. Any unauthorized use of your account or breach of security must be reported to us immediately.'} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Limitation of Liability:" nestedText={' In no event shall TourEase.com, its affiliates, or its partners be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of our website. b. We do not guarantee the accuracy, completeness, or reliability of information provided by hotels or tour operators listed on our website.'} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Indemnification:" nestedText={'You agree to indemnify and hold TourEase.com and its affiliates harmless from any claims, losses, damages, liabilities, and expenses, including legal fees, arising out of your use of our website or violation of these terms.'} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Governing Law:" nestedText={'These terms and conditions are governed by the laws of the region where TourEase.com is registered. Any disputes shall be resolved in accordance with the applicable laws.'} />
</ol>









                    </Box>
                </Box>
            </Box>
            <Footer />
        </Layout>
    );
};

export default Terms;
