import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer";
import { Typography, Box, Grid, Button, Divider } from "@mui/material";
import Layout from "../Components/Layout";
import { WhatsApp } from "@mui/icons-material";
import LocationCard from "../Components/Cards/LocationCard";
import TourData from "../Components/RenderData/TourData";
import HotelData from "../Components/RenderData/HotelData";
import CarData from "../Components/RenderData/CarData";
import TrekData from "../Components/RenderData/TrekData";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import HotelBar from "../Components/HotelData/HotelBar";
import CarBar from "../Components/CarData/CarBar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TourBar from "../Components/TourData/TourBar";
import TrekBar from "../Components/TourData/TrekData";
import Slider from "react-slick"; // For the slider
import "slick-carousel/slick/slick.css"; // Slider styles
import "slick-carousel/slick/slick-theme.css"; // Slider theme styles
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../Firebase/firebase"; // Import your Firebase config

const Home: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  // const [selectedCategory, setSelectedCategory] = useState<'hotel' |'tour' | 'car'| 'trek'>('hotel');
  const [selectedCategory, setSelectedCategory] = useState("hotel");
  const [selectedComponent, setSelectedComponent] = useState("hotel");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 16;
  const [priceFilter, setPriceFilter] = useState<number[]>([0, 1000000]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        console.log("Fetching images from Firestore...");
        const imagesRef = collection(db, "mainSlider");
        const q = query(imagesRef, where("page", "==", "Home"));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          console.log("No images found for the specified page.");
          return;
        }

        const imageUrls = querySnapshot.docs.map((doc) => doc.data().url);
        console.log("Fetched image URLs:", imageUrls);

        setImages(imageUrls);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const renderCategoryData = () => {
    switch (selectedCategory) {
      case "hotel":
        return (
          <HotelData
            currentPage={currentPage}
            hotelsPerPage={itemsPerPage}
            priceFilter={priceFilter}
          />
        );
      case "tour":
        return (
          <TourData
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            priceFilter={priceFilter}
          />
        );
      case "car":
        return (
          <CarData
            currentPage={currentPage}
            carsPerPage={itemsPerPage}
            priceFilter={priceFilter}
          />
        );
      case "trek":
        return (
          <TrekData
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            priceFilter={priceFilter}
          />
        );
      default:
        return null;
    }
  };

  const items = [
    { label: "Hotel", value: "hotel" },
    { label: "Car", value: "car" },
    { label: "Tour", value: "tour" },
    { label: "Trek", value: "trek" },
  ];
  const buttons = [
    { label: "Hotel", value: "hotel" },
    { label: "Tour", value: "tour" },
    { label: "Car", value: "car" },
    { label: "Trek", value: "trek" },
  ];

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const settings = {
    // dots: true, // Uncomment this if you want dots to be visible
    infinite: images && images.length > 1, // Only enable infinite if there is more than one image
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Layout>
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <Box
              key={index}
              component="img"
              src={image}
              alt={`Slider Image ${index}`}
              sx={{ height: "500px", width: "100%", objectFit: "cover" }}
            />
          ))}
        </Slider>

        {/* Text Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: "65%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "white",
            zIndex: 1,
            width: "100%",
            p: { lg: 5, xs: 2 },
            overflow: "hidden", // Hide overflow to ensure content does not exceed bounds
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            From Peaks To Pillows
          </Typography>
          <Typography variant="h6">
            Find your Retreat in Hotel, Resort, Guest House and More
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ mt: { lg: 10, xs: 3 }, mb: { lg: 1, xs: 2 } }}
          >
            {items.map((item) => (
              <Grid item key={item.value}>
                <Typography
                  onClick={() => setSelectedComponent(item.value)}
                  sx={{
                    color:
                      selectedComponent === item.value ? "#e94d50" : "white", // Active tab is red, others are white
                    cursor: "pointer",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    paddingLeft: 1,
                    paddingRight: 1,
                    textDecoration:
                      selectedComponent === item.value ? "underline" : "none",
                    "&:hover": {
                      bgcolor: "white",
                      color: "blue",
                    },
                  }}
                  variant="h6"
                >
                  {item.label}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ mt: 2 }}>
            {" "}
            {/* Added margin for spacing */}
            {selectedComponent === "hotel" && <HotelBar />}
            {selectedComponent === "car" && <CarBar />}
            {selectedComponent === "tour" && <TourBar />}
            {selectedComponent === "trek" && <TrekBar />}
          </Box>
        </Box>
      </Box>

      <Box sx={{ p: 1, mt: 2 }}>
        <Box
          sx={{
            backgroundImage: `url(${require("../Assets/All/Home_image1.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            p: 4,
            m: { lg: 7, xs: 2 },
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <Typography variant="h4" sx={{ color: "white" }}>
                  Customize Your Tour Now
                </Typography>
              </Grid>
              <Grid item lg={3} xs={12}>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Pick your hotels, pick your sight, Customize your travel
                  delights
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained">
                  <WhatsApp /> Submit Request
                </Button>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Box>

      <Box bgcolor={"#e1ebee"} sx={{ p: 1 }}>
        <Box sx={{ m: { lg: 7, xs: 2 } }}>
          <Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Grid item lg={3} md={6}>
              <LocationCard
                imageSrc={require("../Assets/All/Gilgit.png")}
                title="Gilgit"
                carTitle="3 Cars"
                hotelTitle="2 Hotels"
                tourTitle="1 Tour"
                linkTo="/Gilgit"
              />
            </Grid>
            <Grid item lg={3} md={6}>
              <LocationCard
                imageSrc={require("../Assets/All/hunza.png")}
                title="Hunza"
                carTitle="3 Cars"
                hotelTitle="2 Hotels"
                tourTitle="1 Tour"
                linkTo="/Hunza"
              />
            </Grid>

            <Grid item lg={3} md={6}>
              <LocationCard
                imageSrc={require("../Assets/All/skardu.jpg")}
                title="Skardu"
                carTitle="3 Cars"
                hotelTitle="2 Hotels"
                tourTitle="1 Tour"
                linkTo="/Skardu"
              />
            </Grid>

            <Grid item lg={3} md={6}>
              <LocationCard
                imageSrc={require("../Assets/All/khaplu.jpg")}
                title="Khaplu"
                carTitle="3 Cars"
                hotelTitle="2 Hotels"
                tourTitle="1 Tour"
                linkTo="/Khaplu"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box sx={{ m: { lg: 5 } }}>
        <Carousel
          autoPlay={true}
          interval={2000} // Change image every 3 seconds
          infiniteLoop={true}
          showStatus={false}
          showThumbs={false}
          stopOnHover={false}
        >
          <div>
            <img
              src={require("../Assets/All/k2.png")}
              alt="Image 1"
              style={{ height: 120, width: "70%" }}
            />
          </div>
          <div>
            <img
              src={require("../Assets/All/k2_1.png")}
              alt="Image 2"
              style={{ height: 120, width: "70%" }}
            />
          </div>
          <div>
            <img
              src={require("../Assets/All/k2_2.jpg")}
              alt="Image 3"
              style={{ height: 120, width: "70%" }}
            />
          </div>
        </Carousel>
      </Box>

      <Box bgcolor={"#e1ebee"} sx={{ p: 1 }}>
        <Box
          sx={{
            display: "flex",
            ml: { lg: 7, xs: 2 },
            mr: { lg: 7, xs: 2 },
            mt: { lg: 7, xs: 2 },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  mr: { lg: "10rem" },
                }}
              >
                <Typography variant="h5" color={"#142153"} fontWeight={"bold"}>
                  {" "}
                  Just for you
                </Typography>
                <Typography variant="h6" color={"#142153"} fontWeight={"bold"}>
                  Discover Tours, Hotels, Cars
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Grid container spacing={2}>
                  {buttons.map((button) => (
                    <Grid item xs={6} lg={3} key={button.value}>
                      <Button
                        onClick={() => setSelectedCategory(button.value)}
                        sx={{
                          m: 1,
                          borderRadius: "20px",
                          padding: "5px 15px 5px 15px",
                          color:
                            selectedCategory === button.value
                              ? "white"
                              : "black",
                          backgroundColor:
                            selectedCategory === button.value
                              ? theme.palette.primary.main
                              : "white",
                          "&:hover": {
                            backgroundColor:
                              selectedCategory === button.value
                                ? theme.palette.primary.dark
                                : "#e94d50",
                          },
                        }}
                      >
                        {button.label}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box bgcolor={"#e1ebee"} sx={{ p: 1 }}>
          <Box sx={{ m: { lg: 7, xs: 2 } }}>{renderCategoryData()}</Box>
          <Box sx={{ textAlign: "center" }}>
            <Button disabled={currentPage === 1} onClick={handlePreviousPage}>
              Previous
            </Button>
            <Button onClick={handleNextPage}>Next</Button>
          </Box>
        </Box>
      </Box>

      <Box sx={{ p: 1 }}>
        <Box bgcolor={"white"} sx={{ m: { lg: 8, xs: 2 } }}>
          <Typography variant="h4" color={"#142153"}>
            Why TravacationsPakistan.com?
          </Typography>
          <Typography variant="body1" color={"#142153"}>
            Experience the unmatched services, flexibility, and expertise that
            sets us apart
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <FavoriteBorderIcon
                  sx={{ color: "#e94d50", fontSize: 58, mb: 1 }}
                />
                <Typography
                  variant="h5"
                  sx={{ color: "#142153", fontWeight: "bold", mb: 1 }}
                >
                  Local Expertise
                </Typography>
                <Typography
                  variant="body1"
                  color={"#142153"}
                  textAlign="center"
                >
                  Choose us for the advantage of local expertise straight from
                  Gilgit Baltistan, 24/7 with on-the-spot assistance.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <MenuBookIcon sx={{ color: "#e94d50", fontSize: 58, mb: 1 }} />
                <Typography
                  variant="h5"
                  sx={{ color: "#142153", fontWeight: "bold", mb: 1 }}
                >
                  Best Customization
                </Typography>
                <Typography
                  variant="body1"
                  color={"#142153"}
                  textAlign="center"
                >
                  All tours & treks are completely customizable. Make any
                  changes to your itinerary easily to create your dream trip.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <DomainVerificationIcon
                  sx={{ color: "#e94d50", fontSize: 58, mb: 1 }}
                />
                <Typography
                  variant="h5"
                  sx={{ color: "#142153", fontWeight: "bold", mb: 1 }}
                >
                  Escape the Crowd
                </Typography>
                <Typography
                  variant="body1"
                  color={"#142153"}
                  textAlign="center"
                >
                  Ditch the uncomfortable group tours. Enjoy your tour
                  privately, free from any rush. We offer private tours for the
                  best price.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <SentimentSatisfiedAltIcon
                  sx={{ color: "#e94d50", fontSize: 58, mb: 1 }}
                />
                <Typography
                  variant="h5"
                  sx={{ color: "#142153", fontWeight: "bold", mb: 1 }}
                >
                  Comfort & Quality
                </Typography>
                <Typography
                  variant="body1"
                  color={"#142153"}
                  textAlign="center"
                >
                  Our experts physically inspect and verify every hotel based on
                  strict criteria, ensuring you get the best accommodation
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box bgcolor={"#918f96"} color={"white"}>
        <Box sx={{ m: { lg: 8, xs: 2 }, p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  mr: { lg: "10rem" },
                }}
              >
                <Typography variant="h4" fontWeight={"bold"}>
                  Subscribe to our new NewsLetter
                </Typography>
                <Typography variant="h6">
                  Stay up to date with the latest offers, announcements and
                  articles.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} lg={4}>
              <form>
                <Box sx={{ mt: 2 }}>
                  <input
                    type="email"
                    name="EMAIL"
                    placeholder="Email Address"
                    required
                    style={{
                      borderRadius: "30px",
                      padding: "10px 40px",
                      color: "black",
                    }}
                  />
                  <input
                    type="submit"
                    name="submit"
                    value="Subscribe"
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      padding: "10px",
                      borderRadius: "20px",
                      cursor: "pointer",
                      marginLeft: 2,
                    }}
                  />
                </Box>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Layout>
  );
};

export default Home;
