import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import HotelBar from './Components/HotelData/HotelBar';
import CarBar from './Components/CarData/CarBar';

const Test: React.FC = () => {
  const [selectedComponent, setSelectedComponent] = useState<'hotel' | 'car' | null>(null);

  return (
    <Box bgcolor={'#acd8fa'} sx={{ height: { lg: '60vh', xs: '500px' }, p: 1 }}>
      <Grid container spacing={2} justifyContent="center" sx={{mt:2}}>
        <Grid item>
          <Typography onClick={() => setSelectedComponent('hotel')}
             sx={{
              color: 'white',
              '&:hover': {
                bgcolor: '#64b5f6',
              },
              cursor:'pointer',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              paddingLeft:1,
              paddingRight:1
            }}>
            Hotel
          </Typography>
        </Grid>
        <Grid item>
          <Typography onClick={() => setSelectedComponent('car')} 
            sx={{
              color: 'white',
              '&:hover': {
                bgcolor: '#64b5f6',
              },
              cursor:'pointer',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              paddingLeft:1,
              paddingRight:1
            }}>
            Car
          </Typography> </Grid>
        <Grid item>
          <Typography onClick={() => setSelectedComponent('car')}   sx={{
              color: 'white',
              '&:hover': {
                bgcolor: '#64b5f6',
              },
              cursor:'pointer',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              paddingLeft:1,
              paddingRight:1
            }}>
            Tour
          </Typography> </Grid>
        <Grid item>
          <Typography onClick={() => setSelectedComponent('hotel')}
            sx={{
              color: 'white',
              '&:hover': {
                bgcolor: '#64b5f6',
              },
              cursor:'pointer',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              paddingLeft:1,
              paddingRight:1
            }}>
            Trek
          </Typography>
           </Grid>
      </Grid>

      <Box sx={{ mt: { lg: 4, xs: 2 } }}>
        {selectedComponent === 'hotel' && <HotelBar />}
        {selectedComponent === 'car' && <CarBar />}
      </Box>
    </Box>
  );
};

export default Test;

