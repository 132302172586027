import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography, Divider } from "@mui/material";
import Layout from "../../../Components/Layout";
import Footer from "../../../Components/Footer";
import ReviewForm from "../../../Components/HotelData/ReviewForm";
import Review from "../../../Components/HotelData/Review";
import RatingStars from "../../../Components/CarData/RatingStars";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import ImgSlider from "../../../Components/TourData/ImgSlider";
import BlueBox from "../../../Components/TourData/BlueBox";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LanguageIcon from "@mui/icons-material/Language";
import DiscountTable from "../../../Components/TourData/DiscountTable";
import CustomAccordion from "../../../Components/TourData/CustomAccordion";

import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../Firebase/firebase";

import BookingComponent from "../../../Components/TourData/BookingComponent";
import ContactComponent from "../../../Components/TourData/ContactComponent";

interface Trek {
  images: string[];
  name: string;
  email: string;
  tourType: string;
  duration: string;
  price: number;
  groupSize: number;
  phoneNumber: string;
  phoneNumber2: string;
  discountOption: string[];
  services: {
    transport: false;
    meals: false;
    hotelAccommodations: false;
    firstAid: false;
    tourGuide: false;
    tickets: false;
    tollTaxes: false;
  };
  itinerary: string;
  description: string;
}

const TrekDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [trek, setTrek] = useState<Trek | null>(null);
  const [error, setError] = useState<string | null>(null);

  const discountGroups = [
    { id: 1, description: "Group 1", fromAdult: 1, toAdult: 5, value: 10 },
    { id: 2, description: "Group 2", fromAdult: 6, toAdult: 10, value: 15 },
    { id: 3, description: "Group 3", fromAdult: 11, toAdult: 15, value: 20 },
    { id: 4, description: "Group 4", fromAdult: 6, toAdult: 10, value: 18 },
  ];

  useEffect(() => {
    const fetchTrek = async () => {
      if (id) {
        try {
          const tourDoc = doc(firestore, "treks", id);
          const tourSnapshot = await getDoc(tourDoc);
          if (tourSnapshot.exists()) {
            const data = tourSnapshot.data() as Trek;
            setTrek(data);
          } else {
            setError("No such tour!");
          }
        } catch (error) {
          setError("Error fetching tour.");
        }
      }
    };
    fetchTrek();
  }, [id]);

  return (
    <Layout>
      <Box>
        {trek?.images ? (
          <ImgSlider images={trek.images} />
        ) : (
          <Typography variant="body1">Images are not available</Typography>
        )}
      </Box>
      <Box sx={{ m: { lg: 8, xs: 3 } }}>
        <Box sx={{ mt: { lg: 16, xs: 16 } }}>
          <RatingStars
            title={trek?.name || "Trek"}
            initialRating={0}
            reviewCount={0}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6} lg={3}>
                  <BlueBox
                    icon={AccessTimeIcon}
                    iconColor="white"
                    text="Duration"
                    text1={trek?.duration || "N/A"}
                  />
                </Grid>

                <Grid item xs={6} lg={3}>
                  <BlueBox
                    icon={PeopleAltIcon}
                    iconColor="white"
                    text="Tour Type"
                    text1={trek?.tourType || "N/A"}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <BlueBox
                    icon={PeopleAltIcon}
                    iconColor="white"
                    text="Group Size"
                    text1={`${trek?.groupSize || "N/A"}`}
                  />
                </Grid>

                <Grid item xs={6} lg={3}>
                  <BlueBox
                    icon={LanguageIcon}
                    iconColor="white"
                    text="Language"
                    text1="English"
                  />
                </Grid>
              </Grid>
            </Box>

            <Divider sx={{ mt: 5, mb: 5 }} />

            <DiscountTable />

            <Divider sx={{ mt: 5, mb: 5 }} />

            <Box>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                About this tour
              </Typography>
              <Typography variant="body1">
                {trek?.description || "Description not available"}
              </Typography>
            </Box>

            <Divider sx={{ mt: 5, mb: 5 }} />

            <Box>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                Services Included
              </Typography>
              <Box mb={3}>
                <Grid container spacing={2}>
                  {trek &&
                    trek.services &&
                    Object.entries(trek.services)
                      .filter(([_, included]) => included) // Filter only the services that are true
                      .map(([service]) => (
                        <Grid item xs={6} key={service}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <CheckCircleIcon
                              sx={{
                                color: "#42a5f5", // Color for included services
                                fontSize: "large",
                              }}
                            />
                            <Typography variant="body1">
                              {
                                service === "hotelAccommodations"
                                  ? "Accommodation in cozy hotels throughout the tour."
                                  : service === "transport"
                                  ? "Transportation for all mentioned destinations and activities."
                                  : service === "meals"
                                  ? "Meals during the tour."
                                  : service === "firstAid"
                                  ? "First aid kit available."
                                  : service === "tourGuide"
                                  ? "Guided tours to various attractions."
                                  : service === "tickets"
                                  ? "Entrance fees and permits for specified locations."
                                  : service === "tollTaxes"
                                  ? "All toll taxes included."
                                  : service.replace(/([A-Z])/g, " $1") // Convert camelCase to human-readable text
                              }
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                </Grid>
              </Box>
            </Box>

            <Divider sx={{ mt: 5, mb: 5 }} />

            <Box mb={3}>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                Itinerary
              </Typography>
              <CustomAccordion
                title="Explore Each Day"
                detail={trek?.itinerary || "Itinerary not available"}
              />
            </Box>

            <Divider sx={{ mt: 5, mb: 5 }} />

            <Box>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                Frequently asked questions
              </Typography>
              <CustomAccordion
                title="Can I customize the itinerary?"
                detail={
                  " We can accommodate some customization requests based on availability and feasibility. Please contact us for further details."
                }
              />

              <CustomAccordion
                title="What is the cancellation policy?"
                detail={
                  "Our cancellation policy varies depending on the time of cancellation and specific terms and conditions. Please refer to our booking terms or contact us for more information."
                }
              />

              <CustomAccordion
                title="Are meals included in the tour package?"
                detail={
                  "No, meals are not included in the tour package. However, complimentary breakfast is provided daily."
                }
              />
            </Box>

            <Divider sx={{ mt: 5, mb: 5 }} />

            <Box>
              <Review
                rating="4.5"
                totalReviews={10}
                reviewItems={[
                  { label: "Excellent" },
                  { label: "Good" },
                  { label: "Average" },
                  { label: "Poor" },
                  { label: "Terrible" },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <BookingComponent />
            <Box sx={{ marginTop: "25px" }}>
              <ContactComponent
                data={{
                  phoneNumber: trek?.phoneNumber || "N/A",
                  phoneNumber2: trek?.phoneNumber2 || "N/A",
                  email: trek?.email || "N/A",
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ mt: 5, mb: 5 }} />

        <ReviewForm />
      </Box>

      <Footer />
    </Layout>
  );
};

export default TrekDetail;
