import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

interface StarRatingProps {
  title: string;
  initialRating?: number;
  reviewCount?: number;
}

const RatingStars: React.FC<StarRatingProps> = ({ title, initialRating = 0, reviewCount = 0 }) => {
  const [filledStars, setFilledStars] = useState<number>(initialRating);

  const handleStarClick = (starIndex: number) => {
    setFilledStars(starIndex);
  };

  return (
    <Box display="flex" alignItems="center" mb={5} justifyContent="space-between">
      <Typography variant="h4" fontWeight="bold" mr={1}>
        {title}
      </Typography>
      <Box display="flex" flexDirection="column">
        <Typography>Rated</Typography>
        <div>
          {[1, 2, 3, 4, 5].map((index) => (
            <span
              key={index}
              style={{
                fontSize: '1.5rem',
                color: index <= filledStars ? '#FFD700' : 'grey',
                cursor: 'pointer',
              }}
              onClick={() => handleStarClick(index)}
            >
              ★
            </span>
          ))}
        </div>
        <Typography fontSize={13}>from {reviewCount} review{reviewCount !== 1 ? 's' : ''}</Typography>
      </Box>
    </Box>
  );
};

export default RatingStars;
