import React from 'react';
import { Box,  Typography } from '@mui/material';
import Layout from '../../../Components/Layout';
import Footer from '../../../Components/Footer';


import backgroundImage from '../../../Assets/All/hunza.png';
import HotelCard from '../../../Components/Cards/HotelCard';


const Hunza: React.FC = () => {

    const hotelData = {
        imageSrc: require('../../../Assets/Hotel/hunzaelites.png') ,
        title: 'Hunza Elites',
        price: '12000',
        rating: 2,
        linkTo: '/HotelElite',
        days: '5 Nights'
      };
    return (
        <Layout>
           
            <Box >
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '70vh',

                    }}>
                     <Typography variant='h4' color={'white'} fontWeight='bold' sx={{pt:{lg:25,xs:5},pl:{lg:10,xs:2}}}>Hunza</Typography>
                </Box>

                <Box sx={{ mb: { lg: 10, xs: 3 }, ml: { lg: 10, xs: 3 }, mr: { lg: 10, xs: 3 } , mt: { lg: 5, xs: 3 } }}>
                    <Typography fontSize={34} textAlign={'center'} fontWeight='bold'>Hunza</Typography>
                    <Typography variant='body1' mb={2} >Nestled amidst the majestic peaks of the Karakoram Mountains, Hunza Valley in Gilgit-Baltistan, Pakistan, is often referred to as “Shangri-La,” a mythical paradise. This breathtaking valley, with its fertile land, cascading waterfalls, and snow-capped peaks, offers a unique blend of stunning scenery, rich cultural heritage, and a welcoming community.</Typography>
               
                    <Typography fontSize={22} mb={2} fontWeight='bold'>
  A Landscape of Enchantment:
</Typography>
<Typography variant='body1' mb={4}>
  Hunza Valley boasts a captivating landscape. The mighty Hunza River, fed by glaciers, carves its way through the valley, flanked by lush green meadows and orchards. Towering peaks like Rakaposhi and Ladyfinger Peak dominate the horizon, creating a dramatic backdrop. Attabad Lake, formed by a devastating landslide in 2010, adds a unique touch to the valley’s charm.
</Typography>

<Typography fontSize={22} mb={2} fontWeight='bold'>
  A Rich Cultural Tapestry:
</Typography>
<Typography variant='body1' mb={4}>
  Hunza possesses a rich cultural heritage dating back centuries. The historic Baltit Fort, perched atop a rock overlooking the valley, stands as a testament to the region’s past. Traditional music and dance performances, vibrant festivals like the Apricot Festival, and centuries-old irrigation systems known as “karez” offer a glimpse into the unique customs and traditions of the local people.
</Typography>

<Typography fontSize={22} mb={2} fontWeight='bold'>
  Beyond the Scenery:
</Typography>
<Typography variant='body1' mb={4}>
  Beyond the captivating scenery and cultural immersion, Hunza offers a plethora of activities for every kind of traveler. Trekking enthusiasts can embark on challenging expeditions through the nearby mountains, while nature lovers can explore the scenic Altit and Gulmit villages. The valley also offers opportunities for jeep safaris, white water rafting, and paragliding, catering to the adventurous spirit.
</Typography>

<Typography fontSize={22} mb={2} fontWeight='bold'>
  The Myth of Longevity:
</Typography>
<Typography variant='body1' mb={4}>
  Hunza Valley has gained widespread fame for its alleged population’s longevity. While claims of centenarians being commonplace have been disputed, the healthy lifestyle and diet of the Hunza people, characterized by locally grown fruits and vegetables, are noteworthy.
</Typography>

<Typography fontSize={22} mb={2} fontWeight='bold'>
  Planning Your Hunza Adventure:
</Typography>
<Typography variant='body1' mb={4}>
  The best time to visit Hunza Valley is during the spring and summer months, from April to October, when the weather is pleasant and the skies are clear. Getting to Hunza involves flying into Skardu Airport and then taking a scenic road trip.
</Typography>

<Typography variant='body1' mb={4}>
  Hunza Valley is more than just a tourist destination; it’s an experience. Whether you seek breathtaking landscapes, cultural immersion, thrilling adventures, or a glimpse into a unique way of life, Hunza has something to offer everyone. So, pack your bags, embrace the spirit of exploration, and discover the magic of this hidden gem in the heart of the Himalayas.
</Typography>

     

                    
                </Box>

             
                </Box>
          
            <Footer />
        </Layout>
    );
};

export default Hunza;