import React from 'react';
import { Typography } from '@mui/material';

interface ListItemProps {
    text: string;
    nestedText: string;
}

const  Listitem: React.FC<ListItemProps> = ({ text, nestedText }) => {
    return (
        <Typography mt={2}>
            <li>
                <strong>{text}</strong>
                <ol style={{ listStyleType:'circle', marginLeft:'30px',marginTop:5 }}>
                    <li>{nestedText}</li>
                </ol>
            </li>
        </Typography>
    );
};

export default Listitem;
