import React, { FC } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';


interface AccordionProps {
    summaryText: string;
    detailsText: string;
}

const  CustomAccordion: FC<AccordionProps> = ({ summaryText, detailsText }) => {
    return (
        <Accordion sx={{mb:2}}>
            <AccordionSummary
                                expandIcon={<AddIcon sx={{ bgcolor: 'blue', color:'white' ,borderRadius:20  }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography fontWeight='bold' variant='h6'>{summaryText}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{detailsText}</Typography>
            </AccordionDetails>
        </Accordion>
    );
}

export default  CustomAccordion;
