// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Test from './Test';
// import Home from './Screens/Home';
// import Topbar from './Components/Topbar';
// import Hotel from './Screens/Hotel';
// import Tour from './Screens/Tour';
// import Car from './Screens/Car';
// import Trek from './Screens/Trek';
// import CustomTour from './Screens/CustomTour';
// import Cancel from './Screens/SupportPages/Cancel';

// import Cookies from './Screens/SupportPages/Cookies';
// import Payment from './Screens/SupportPages/Payment';
// import Terms from './Screens/SupportPages/Terms';
// import AboutUs from './Screens/Company/AboutUs';
// import HelpCenter from './Screens/Company/HelpCenter';
// import TravelArticle from './Screens/Company/TravelArticle';
// import Careers from './Screens/Company/Careers';
// import Partnership from './Screens/Contact/Partnership';
// import Faq from './Screens/Contact/Faq';
// import G_in_touch from './Screens/Contact/G_in_touch';
// import HotelElite from './Screens/InnerScreens/AllHotels/HotelElite';
 
 

// const App: React.FC = () => {
//   return (
//     <Router>
      
    
//       <Routes>
//         <Route path="/test" element={<Test/>} />
//         <Route path="/" element={<Home />} />
//         <Route path="/Hotel" element={<Hotel/>} />
//         <Route path="/Tour" element={<Tour/>} />
//         <Route path="/Trek" element={<Trek/>} />
//         <Route path="/Car" element={<Car/>} />
//         <Route path="/CustomTour" element={<CustomTour/>} />


// {/* ---------Support------------ */}
// <Route path="/Cancel" element={<Cancel/>} />
// <Route path="/Cookies" element={<Cookies/>} />
// <Route path="/Payment" element={<Payment/>} />
// <Route path="/Term" element={<Terms/>} />


// {/* ---------Company------------ */}
// <Route path="/Aboutus" element={<AboutUs/>} />
// <Route path="/HelpCenter" element={<HelpCenter/>} />
// <Route path="/TravelArticle" element={<TravelArticle/>} />
// <Route path="/Careers" element={<Careers/>} />

// {/* ---------Contact------------ */}
// <Route path="/Partnership" element={<Partnership/>} />
// <Route path="/Faq" element={<Faq/>} />
// <Route path="/InTouch" element={<G_in_touch/>} />


// {/* ---------HotelElite------------ */}
// <Route path="/HotelElite" element={<HotelElite/>} />
 


//       </Routes>
//     </Router>
//   );
// };

// export default App;



import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Topbar from './Components/Topbar';
import routes from './Screens/routes';

const App: React.FC = () => {
  return (
    <Router>
      <Topbar />
      <Routes>
        {routes.map(({ path, element }) => (
          <Route   path={path} element={element} />
        ))}
      </Routes>
    </Router>
  );
};

export default App;
