import { Divider } from '@mui/material';
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';
interface TourCardProps {
  src: string;
  title: string;
  price: string;
  days: number;
  linkTo: string;
}

const TourCard: React.FC<TourCardProps> = ({ src, title, price, days,linkTo }) => {
  return (
    <Link to={linkTo} style={{ textDecoration: 'none', color: 'inherit' }}>
    <div style={{ height:"100%",width: '100%',minHeight:"200px", maxWidth: '280px', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',backgroundColor:'white',marginBottom:'1rem' }}>
      <div style={{ position: 'relative' }}>
        <img
          style={{ width: '100%', height: '100%',objectFit:"cover" ,minHeight:"200px"  }}
          src={src}
          alt={title}
          className="  transition-transform duration-300 transform hover:scale-110 cursor-pointer"
        />
     
      </div>
      <div style={{paddingLeft:15 ,paddingRight:15}}>
      
        <h2 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '10px',marginTop:'10px' }}>{title}</h2>
        <Divider style={{ width: '100%',color:'black', height:'5px' }}/>
        <div style={{ padding: '10px',display:'flex',flexDirection:'row' ,justifyContent:'space-between',marginTop:20}}>
        <p style={{ marginBottom: '5px' }}> Price:<strong>{price}</strong></p>
        <p style={{ marginBottom: '5px',fontSize:16 }}><strong> <AccessTimeIcon sx={{fontSize:10}}/>{days}</strong></p>
      </div>
      </div>
    </div>
    </Link>
  );
};

export default TourCard;
