import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface ImageCarouselProps {
    images: string[];
}

const ImgSlider: React.FC<ImageCarouselProps> = ({ images }) => {
    return (
        <div className=" h-80 sm:h-96  xl:h-80 2xl:h-96">
        <Carousel autoPlay infiniteLoop interval={1000} showStatus={false} showThumbs={false}>
        {images.map((image, index) => (
            <div key={index}>
                <img src={image} alt={`Slide ${index}`} style={{height:440}} />
            </div>
        ))}
    </Carousel>
    </div>
    );
};

export default ImgSlider;

