import React from "react";
import {
  Box,
  Grid,
  Typography,
  Slider,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
  Paper,
} from "@mui/material";
import Layout from "../../Components/Layout";
import Footer from "../../Components/Footer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { TextField } from "@mui/material";

import backgroundImage from "../../Assets/All/Building.png";

const G_in_touch: React.FC = () => {
  return (
    <Layout>
      <Box>
        <Box
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            minHeight: "70vh",
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ pt: { lg: 25, xs: 5 }, pl: { lg: 10, xs: 2 } }}
          >
            Get In Touch
          </Typography>
        </Box>

        <Box sx={{ ml: { lg: 10, xs: 3 }, mr: { lg: 10, xs: 3 } }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box mt={"9rem"}>
                <Typography variant="h5" fontWeight="bold" mb={2}>
                  Contact information
                </Typography>
                <Typography>
                  Have a question, a suggestion, or just want to say hello? We'd
                  love to hear from you! Feel free to reach out using the
                  contact details below. Our team is here to assist you and
                  ensure your experience with Easilytrip.com is seamless and
                  enjoyable. Your feedback matters, and we look forward to
                  connecting with you!
                </Typography>

                <Typography variant="body1" mb={3} mt={5}>
                  <LocationOnIcon sx={{ mr: 1 }} />
                  House # 26-A Satellite Town, Skardu, Gilgit Baltistan,
                  Pakistan
                </Typography>
                <Divider />

                <Typography variant="body1" mb={3} mt={2}>
                  <WhatsAppIcon sx={{ mr: 1 }} />
                  Whatsapp: +92 312 6494463
                </Typography>
                <Divider />

                <Typography variant="body1" mb={3} mt={2}>
                  <EmailIcon sx={{ mr: 1 }} />
                  contact@tourease.com
                </Typography>
                <Divider />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box sx={{ m: { lg: 10, xs: 3 } }}>
                <Paper elevation={3} sx={{ padding: 3, borderRadius: 5 }}>
                  <Typography fontSize={28} mb={4} fontWeight="bold">
                    Send a message
                  </Typography>
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                        <TextField
                          required
                          fullWidth
                          label="Your name"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                        <TextField
                          required
                          fullWidth
                          label="Phone number"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                        <TextField
                          required
                          fullWidth
                          label="Your email"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                          required
                          fullWidth
                          label="Message"
                          multiline
                          rows={4}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          Send Message
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Layout>
  );
};

export default G_in_touch;
