import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TourCard from "../Cards/TourCard";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../Firebase/firebase"; // Make sure this path is correct

interface TourDataProps {
  currentPage: number;
  itemsPerPage: number;
  priceFilter: number[];
}

const TourData: React.FC<TourDataProps> = ({
  currentPage,
  itemsPerPage,
  priceFilter,
}) => {
  const [tourData, setTourData] = useState<any[]>([]);

  useEffect(() => {
    const fetchTours = async () => {
      const querySnapshot = await getDocs(collection(firestore, "tours"));
      const toursData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTourData(toursData);
    };

    fetchTours();
  }, []);

  // Apply price filter
  const filteredTourData = tourData.filter((tour) => {
    return tour.price >= priceFilter[0] && tour.price <= priceFilter[1];
  });

  // Calculate start and end index for current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <Grid container spacing={1} sx={{ display: "flex", flexDirection: "row" }}>
      {filteredTourData.slice(startIndex, endIndex).map((tour) => (
        <Grid item key={tour.id} lg={4} md={6}>
          <TourCard
            src={tour.images[0]} // Assuming images is a single URL string
            title={tour.name} // Adjust according to your tour data structure
            price={tour.price.toString()}
            days={tour.duration}
            linkTo={`/TourDetail/${tour.id}`} // Assuming you're linking to a tour view page
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TourData;
