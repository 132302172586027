import React, { useState } from 'react';
import { Box, Paper, Typography, Divider, TextField, IconButton, Button } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

const BookingComponent: React.FC = () => {
  const [adults, setAdults] = useState<number>(1);
  const [infant, setInfant] = useState<number>(1);
  const [numGuests, setNumGuests] = useState<number>(0);
  const [guestNames, setGuestNames] = useState<string[]>(['']);

  const handleIncrement = () => {
    setAdults((prevAdults) => prevAdults + 1);
  };

  const handleDecrement = () => {
    setAdults((prevAdults) => (prevAdults > 1 ? prevAdults - 1 : 1));
  };

  const handleIncrement2 = () => {
    setInfant((prevInfant) => prevInfant + 1);
  };

  const handleDecrement2 = () => {
    setInfant((prevInfant) => (prevInfant > 1 ? prevInfant - 1 : 1));
  };

  const handleNumGuestsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newNumGuests = parseInt(event.target.value);
    setNumGuests(newNumGuests > 0 ? newNumGuests : 0);
    setGuestNames(Array(newNumGuests).fill(''));
  };

  const handleGuestNameChange = (index: number, value: string) => {
    const updatedGuestNames = [...guestNames];
    updatedGuestNames[index] = value;
    setGuestNames(updatedGuestNames);
  };

  const currentDate = new Date().toISOString().split('T')[0];

  return (
    <Box component={Paper} elevation={3} p={2} borderRadius={3}>
      <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography>From: <strong>PKR40,000</strong></Typography>
        <Typography><strong>0</strong>(0 Reviews)</Typography>
      </Box>
      <Box mt={2} mb={3} component={Paper} elevation={1} p={3} borderRadius={3}>
        <Box mt={2}>
          <TextField
            label="Date"
            type="date"
            value={currentDate}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '1.45rem', color: 'black', fontWeight: 'bold' },
            }}
            InputProps={{
              disableUnderline: true, // This removes the underline
            }}
            variant="standard"
            fullWidth
          />
        </Box>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Box mt={2} display="flex" justifyContent={'space-between'}>
          <Box display="flex" flexDirection={'column'}>
            <Typography fontWeight='bold'>Adults:</Typography>
            <Typography>Above 5</Typography>
          </Box>
          <Box display="flex" flexDirection={'row'} textAlign={'center'}>
            <IconButton onClick={handleDecrement} aria-label="Decrement" sx={{ border: '1px solid black' }}>
              <RemoveIcon />
            </IconButton>
            <Typography mt={1} ml={2} mr={2} fontWeight='bold'>{adults}</Typography>
            <IconButton onClick={handleIncrement} aria-label="Increment" sx={{ border: '1px solid black' }}>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>

        <Divider sx={{ mt: 1, mb: 1 }} />
        <Box mt={2} display="flex" justifyContent={'space-between'}>
          <Box display="flex" flexDirection={'column'}>
            <Typography fontWeight='bold'>Infant:</Typography>
            <Typography>Above 3</Typography>
          </Box>
          <Box display="flex" flexDirection={'row'} textAlign={'center'}>
            <IconButton onClick={handleDecrement2} aria-label="Decrement" sx={{ border: '1px solid black' }}>
              <RemoveIcon />
            </IconButton>
            <Typography mt={1} ml={2} mr={2} fontWeight='bold'>{infant}</Typography>
            <IconButton onClick={handleIncrement2} aria-label="Increment" sx={{ border: '1px solid black' }}>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Box>
          <TextField
            label="Number of Guests"
            type="number"
            value={numGuests}
            onChange={handleNumGuestsChange}
            variant="outlined"
          />
          {[...Array(numGuests)].map((_, index) => (
            <TextField
              key={index}
              label={`Guest ${index + 1} Name`}
              value={guestNames[index] || ''}
              onChange={(event) => handleGuestNameChange(index, event.target.value)}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          ))}
        </Box>

        <Box mt={3} sx={{ textAlign: 'center' }}>
          <Button sx={{ borderRadius: 8 }} variant='contained'>Book Now</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingComponent;
