import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography, Divider } from "@mui/material";
import Layout from "../../../Components/Layout";
import Footer from "../../../Components/Footer";
import ImageGrid from "../../../Components/HotelData/ImageGrid";
import SearchBar from "../../../Components/HotelData/HotelBar";
import HotelFacilities from "../../../Components/HotelData/HotelFacilities";
import Review from "../../../Components/HotelData/Review";
import ReviewForm from "../../../Components/HotelData/ReviewForm";

import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../Firebase/firebase";

import Availabilty from "../../../Components/HotelData/Availabilty";

interface Hotel {
  images: string[];
  name: string;
  email: string;
  address: string;
  duration: string;
  price: number;
  phoneNumber: number;
  phoneNumber2: number;
  description: string;
  facilities: string[]; // Add facilities to the interface for better typing
  capacity: string;
}

const HotelDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [hotel, setHotel] = useState<Hotel | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHotel = async () => {
      if (id) {
        try {
          const hotelDoc = doc(firestore, "hotels", id);
          const hotelSnapshot = await getDoc(hotelDoc);
          if (hotelSnapshot.exists()) {
            const data = hotelSnapshot.data() as Hotel;
            setHotel(data);
          } else {
            setError("No such hotel!");
          }
        } catch (error) {
          setError("Error fetching hotel.");
        }
      }
    };
    fetchHotel();
  }, [id]);
  return (
    <Layout>
      <Box p={1}></Box>
      <Box sx={{ m: { lg: 5, xs: 3 } }}>
        {hotel && (
          <ImageGrid
            images={hotel.images || []} // Ensure images array is available, use empty array as fallback
            title={hotel.name || "Hotel Name Not Available"} // Fallback title if name is missing
            address={hotel.address || "Address not available"} // Fallback address if address is missing
            aboutTitle="About this hotel"
            aboutText={hotel.description || "Description not available"} // Fallback for description
          />
        )}
        <Divider sx={{ mt: 5, mb: 5, width: "100%" }} />
        <Box>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Capacity
          </Typography>
          <Box display="flex" alignItems="center">
            {/* SVG Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="24"
              height="24"
              fill="currentColor"
              style={{
                marginRight: "8px",
                marginBottom: "10px",
              }} // Add some spacing between the icon and text
            >
              <path
                d="M0 32C0 14.3 14.3 0 32 0L480 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l0 384c17.7 0 32 14.3 32 32s-14.3 32-32 32l-176 0 0-48c0-26.5-21.5-48-48-48s-48 21.5-48 48l0 48L32 512c-17.7 0-32-14.3-32-32s14.3-32 32-32L32 64C14.3 64 0 49.7 0 32zm96 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8l144 0z"
                fill="#1976d2"
              />
            </svg>

            {/* Capacity Text */}
            <Typography variant="h5" gutterBottom>
              {hotel?.capacity || "Capacity not available"}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ mt: 5, mb: 5, width: "100%" }} />

        <HotelFacilities facilities={hotel?.facilities || []} />

        <Divider sx={{ mt: 5, mb: 5 }} />

        <Review
          rating="4.5"
          totalReviews={10}
          reviewItems={[
            { label: "Cleanliness" },
            { label: "Communication" },
            { label: "Check-in" },
            { label: "Accuracy" },
            { label: "Location" },
          ]}
        />

        <Divider sx={{ mt: 5, mb: 5 }} />

        <Box>
          <Typography variant="h5" mb={1} fontWeight="bold">
            Availabilty
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <SearchBar />
            </Grid>
          </Grid>

          <Availabilty
            imageUrl={require("./HotelsImages/elite_1.jpg")}
            roomSize="22cm"
            numBeds={3}
            numChildren={1}
            numAdults={2}
          />
        </Box>

        <Divider sx={{ mt: 5, mb: 5 }} />

        <ReviewForm />
      </Box>

      <Footer />
    </Layout>
  );
};

const iconStyle: React.CSSProperties = {
  fontSize: 48,
};

export default HotelDetail;
