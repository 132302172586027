import { useState, useEffect } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../Firebase/firebase";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

interface ContactDataProps {
  data: {
    phoneNumber: string;
    phoneNumber2: string;
    email: string;
  };
}

const ContactComponent: React.FC <ContactDataProps> = ({data}) => {
  const [contactData, setContactData] = useState<any[]>([]);


  // Use the first tour from the data, or handle empty data

  return (
    <Grid>
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#333" }}>
          Contact Information
        </Typography>
      </Box>
      <Box
        component={Paper}
        elevation={3}
        p={3}
        borderRadius={2}
        sx={{ backgroundColor: "#f5f5f5" }}
      >
        <Box
          mb={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderBottom: "1px solid #e0e0e0", pb: 1 }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "#333" }}
          >
            <PhoneIcon sx={{ color: "#333" }} />
            Phone Number
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "#555" }}
          >
            {data.phoneNumber}
          </Typography>
        </Box>

        <Box
          mb={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderBottom: "1px solid #e0e0e0", pb: 1 }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "#333" }}
          >
            <PhoneIcon sx={{ color: "#333" }} />
            Phone Number 2
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "#555" }}
          >
            {data.phoneNumber2}
          </Typography>
        </Box>

        <Box
          mb={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderBottom: "1px solid #e0e0e0", pb: 1 }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "#333" }}
          >
            <EmailIcon sx={{ color: "#333" }} />
            Email
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "#555" }}
          >
            {data.email}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default ContactComponent;
