import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HotelIcon from "@mui/icons-material/Hotel";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ExploreIcon from "@mui/icons-material/Explore";
import TerrainIcon from "@mui/icons-material/Terrain";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Grid,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";

interface TopbarProps {
  window?: () => Window;
}

const drawerWidth = 240;

const Topbar: React.FC<TopbarProps> = ({ window }) => {
  const [mobileOpen, setMobileOpen] = useState(
    window && window().innerWidth < 800 ? true : false
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [signupDialogOpen, setSignupDialogOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const handleLoginOpen = () => {
    setLoginDialogOpen(true);
    setMenuAnchorEl(null);
  };

  const handleSignupOpen = () => {
    setSignupDialogOpen(true);
    setMenuAnchorEl(null);
  };

  const handleLoginClose = () => {
    setLoginDialogOpen(false);
  };

  const handleSignupClose = () => {
    setSignupDialogOpen(false);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to={"/"}>Travacations </Link>
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/Hotel">
            <HotelIcon />
            <ListItemText primary="Hotel" sx={{ ml: 2 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/Car">
            <DirectionsCarIcon />
            <ListItemText primary="Car" sx={{ ml: 2 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/Tour">
            <ExploreIcon />
            <ListItemText primary="Tour" sx={{ ml: 2 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/Trek">
            <TerrainIcon />
            <ListItemText primary="Treks" sx={{ ml: 2 }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ mb: 0 }}>
      <AppBar component="nav" sx={{ p: 1,backgroundColor:"transparent", position: "absolute" }} className="test1">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{ display: { xs: "flex", sm: "none" }, alignItems: "center" }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>

            <Link to={"/"}>
              <Typography
                variant="h5"
                component="div"
                color="white"
                textAlign="center"
                sx={{ ml: 5 }}
              >
                Travacations
              </Typography>
            </Link>
            <IconButton color="inherit" sx={{ ml: 5 }}>
              <ShoppingCartIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", ml: 5 }}>
              <Link to={"/"}>
                <Typography variant="h4" component="div">
                  <Box
                    component={"img"}
                    sx={{
                      width: 90,
                      height: 90,
                      top: 2,
                      left: 2.07,
                    }}
                    src={require("../Assets/travactions-pakistan-tours-website-best-travel-website.png")}
                  />
                </Typography>
              </Link>
              <Box sx={{ ml: 8 }}>
                <Button
                  sx={{
                    color: "#1976d2",
                    borderRadius: "20px",
                    padding: "5px 10px 2px 10px",
                    "&:hover": { backgroundColor: "#1976d2", color: "white", padding: "5px 10px 2px 10px" },
                    mr: 5,
                  }}
                >
                  <Link
                    to={"/Hotel"}
                    style={{ fontSize: 15, fontWeight: "bold" }}
                  >
                    <HotelIcon sx={{ mb: 1, mr:1 }} />
                    Hotel
                  </Link>
                </Button>
                <Button
                  sx={{
                    color: "#1976d2",
                    borderRadius: "20px",
                    padding: "5px 10px 2px 10px",
                    "&:hover": {  backgroundColor: "#1976d2", color: "white", padding: "5px 10px 2px 10px" },
                    mr: 5,
                  }}
                >
                  <Link
                    to={"/Car"}
                    style={{ fontSize: 15, fontWeight: "bold" }}
                  >
                    <DirectionsCarIcon sx={{ mb: 1, mr:1 }} />
                    Cars
                  </Link>
                </Button>
                <Button
                  sx={{
                    color: "#1976d2",
                    borderRadius: "20px",
                    padding: "5px 10px 2px 10px",
                    "&:hover": {  backgroundColor: "#1976d2", color: "white", padding: "5px 10px 2px 10px" },
                    mr: 5,
                  }}
                >
                  <Link
                    to={"/Tour"}
                    style={{ fontSize: 15, fontWeight: "bold" }}
                  >
                    <ExploreIcon sx={{ mb: 1, mr:1 }} />
                    Tour
                  </Link>
                </Button>
                <Button
                  sx={{
                    color: "#1976d2",
                    borderRadius: "20px",
                    padding: "5px 10px 2px 10px",
                    "&:hover": { backgroundColor: "#1976d2", color: "white", padding: "5px 10px 2px 10px" },
                  }}
                >
                  <Link
                    to={"/Trek"}
                    style={{ fontSize: 15, fontWeight: "bold" }}
                  >
                    <TerrainIcon sx={{  mb: 1, mr:1 }} />
                    Treks
                  </Link>
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: { xs: "flex", sm: "flex" }, ml: "auto" }}>
              <Grid container spacing={3}>
                <Grid item xs={6} lg={3}>
                  <Link to={"/CustomTour"}>
                    <Button
                      sx={{
                        backgroundColor: "#FFC300",
                        "&:hover": {
                          backgroundColor: "#0047AB",
                          color: "white",
                        },
                      }}
                    >
                      Custom
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Typography
                    variant="body1"
                    textAlign={"center"}
                    mt={1}
                    ml={3}
                  >
                    PKR
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <IconButton color="inherit" aria-label="cart">
                    <ShoppingCartIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <IconButton
                    color="inherit"
                    aria-label="sign-in"
                    onClick={handleMenuOpen}
                  >
                    <AccountCircleIcon />
                  </IconButton>
                  <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleLoginOpen}>Login</MenuItem>
                    <MenuItem onClick={handleSignupOpen}>Sign Up</MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box>
        {/* Login Dialog */}
        <Dialog open={loginDialogOpen} onClose={handleLoginClose}>
          <DialogTitle>Log In</DialogTitle>
          <DialogContent>
            <TextField
              label="Email or Username"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </DialogContent>

          <Button
            variant="contained"
            onClick={handleLoginClose}
            sx={{ m: 2 }}
            color="primary"
          >
            Login
          </Button>

          <Button onClick={handleLoginClose} color="primary">
            Forgot Password?
          </Button>

          <Button variant="contained" color="success" sx={{ m: 2 }}>
            Twitter
          </Button>

          <Box textAlign="center">
            <Typography variant="body2">
              Do not have an account?{" "}
              <Button onClick={handleSignupOpen}>Sign Up</Button>
            </Typography>
          </Box>
        </Dialog>

        {/* Signup Dialog */}
        <Dialog open={signupDialogOpen} onClose={handleSignupClose}>
          <DialogTitle>Sign Up</DialogTitle>
          <DialogContent>
            <TextField
              label="Username *"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Full Name"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email *"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Password *"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              select
              label="Select User Type"
              variant="outlined"
              fullWidth
              margin="normal"
            >
              <MenuItem value="normal">Normal User</MenuItem>
              <MenuItem value="partner">Partner User</MenuItem>
            </TextField>
          </DialogContent>
          <Box display={"flex"} flexDirection={"column"}>
            <Button
              onClick={handleSignupClose}
              variant="contained"
              color="primary"
              sx={{ m: 2 }}
            >
              Sign Up
            </Button>
            <Button
              onClick={handleSignupClose}
              variant="contained"
              color="primary"
              sx={{ m: 2, bgcolor: "#3b55a0" }}
            >
              Continue with Facebook
            </Button>
            <Button
              onClick={handleSignupClose}
              variant="contained"
              color="primary"
              sx={{ m: 2 }}
            >
              Twitter
            </Button>
          </Box>

          <Box textAlign="center">
            <Typography variant="body2">
              Already have an account?{" "}
              <Button onClick={handleLoginOpen}>Log In</Button>
            </Typography>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Topbar;
