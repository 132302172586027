import React from "react";
import { Box, Typography, Grid } from "@mui/material";

interface Facility {
  name: string;
}

interface HotelFacilitiesProps {
  facilities: string[];
}

const HotelFacilities: React.FC<HotelFacilitiesProps> = ({ facilities }) => {
  return (
    <Box>
      <Typography variant="h5" mb={1} fontWeight="bold">
        Hotel Facilities 
      </Typography>
      <Grid container spacing={1}>
        {facilities.map((facility, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box display="flex" alignItems="center">
              {/* SVG Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={{
                  marginRight: "8px",
                  marginBottom: "10px",
                  width: "24px",
                  height: "24px",
                }}
              >
                <path
                  d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"
                  fill="#1976d2"
                />
              </svg>

              {/* Facility Text */}
              <Typography variant="h6" gutterBottom>
                {facility}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HotelFacilities;
