import React from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox ,Paper} from '@mui/material';
import Layout from '../../Components/Layout';
import Footer from '../../Components/Footer';


import backgroundImage from '../../Assets/All/Building.png';
import CustomAccordion from './CustomAccordion';


const Faq: React.FC = () => {
    return (
        <Layout>
            <Box>
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom',
                        minHeight: '70vh',

                    }}>
                     <Typography variant='h4' fontWeight='bold' sx={{pt:{lg:25,xs:5},pl:{lg:10,xs:2}}}>FAQ</Typography>
                </Box>

                <Box sx={{ mb: { lg: 10, xs: 3 }, ml: { lg: 10, xs: 3 }, mr: { lg: 10, xs: 3 } , mt: { lg: 5, xs: 3 } }}>
                    <Typography fontSize={28} mb={1} fontWeight='bold' textAlign={'center'}>Frequently asked questions</Typography>
                </Box>










                <Box sx={{ m: { lg: 12, xs: 3 } }}>

                <Grid container>
            
            <Grid item xs={12} lg={6}>
                <Paper sx={{padding:1,borderRadius:2}}>
                <CustomAccordion
    summaryText="How can I make a hotel reservation on HotelsinSkardu.com?"
    detailsText="Making a reservation is easy! Simply visit our website, enter your destination, select your dates, and browse through the available hotels. Once you find the perfect stay, follow the prompts to complete your booking."
/>

<CustomAccordion
    summaryText="Are there any hidden fees when booking through your site?"
    detailsText="No hidden fees! The price you see during the booking process is the price you'll pay. We believe in transparency and strive to provide a clear and straightforward booking experience."
/>

<CustomAccordion
    summaryText="Can I modify or cancel my reservation?"
    detailsText="Yes, in most cases, you can modify or cancel your reservation. Check the specific hotel's cancellation policy during the booking process, or contact our customer support for assistance."
/>

<CustomAccordion
    summaryText="How do I contact customer support for assistance?"
    detailsText="You can reach our customer support team. We're here to help you with any questions, concerns, or assistance you may need."
/>

                </Paper>
            </Grid>

           
            <Grid item xs={12} lg={6}>
            <Paper sx={{padding:1,borderRadius:2}}>
            <CustomAccordion
    summaryText="What types of payment do you accept?"
    detailsText="We accept various payment methods, including major credit cards. During the booking process, you can select your preferred payment method."
/>

<CustomAccordion
    summaryText="How do I know if my reservation is confirmed?"
    detailsText="Once you complete the booking process, you will receive a confirmation email with all the details of your reservation. Please check your email and ensure all information is accurate."
/>

<CustomAccordion
    summaryText="Is my personal information secure when using your website?"
    detailsText="Yes, we take your privacy seriously. Our website uses secure encryption protocols to protect your personal information. You can learn more about our privacy policy here."
/>

<CustomAccordion
    summaryText="Do you offer special rates for group bookings or corporate events?"
    detailsText="Absolutely! We offer special rates for group bookings and corporate events. Contact our dedicated team to discuss your specific requirements and get personalized assistance."
/>

                </Paper>
            </Grid>
        </Grid>
                </Box>


                <Box sx={{ m: { lg: 10, xs: 3 } }}>

                <Grid container spacing={2}>
               
                <Grid item xs={12} lg={6}>
                    <Box >
                        <img src={require('../../Assets/All/Faq.png')} alt="Your Image" style={{ width: '100%', height: 'auto',borderRadius:20 }} />
                    </Box>
                </Grid>
                
             
                <Grid item xs={12} lg={6}>
                    <Box  sx={{m:{lg:10}}}>
                        <Typography variant="h5" fontWeight='bold' textAlign={'center'} sx={{mt:{lg:'20rem'}}}>Get special offers, and more from Hotelsinskardu.com.</Typography>
                        <Typography variant="body1" pl={7} pr={7} color={'gray'} textAlign={'center'} mt={1}>Subscribe to see secret deals prices drop the moment you sign up</Typography>
                    </Box>
                </Grid>
            </Grid>


                </Box>


                </Box>
            <Footer />
        </Layout>
    );
};

export default Faq;
