import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../Firebase/firebase"; // Ensure this path is correct

const DiscountTable: React.FC = () => {
  const [tourData, setTourData] = useState<any[]>([]);

  useEffect(() => {
    const fetchTours = async () => {
      const querySnapshot = await getDocs(collection(firestore, "tours"));
      const toursData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTourData(toursData);
    };

    fetchTours();
  }, []);
  
  // Function to parse discount string
  const parseDiscountString = (discountString: string) => {
    const percentageMatch = discountString.match(/(\d+)%/);
    const percentage = percentageMatch ? parseInt(percentageMatch[1]) : null;

    const peopleMatch = discountString.match(/of (\d+) people/);
    const numberOfPeople = peopleMatch ? parseInt(peopleMatch[1]) : null;

    const discountGroup = discountString
      .replace(percentageMatch ? percentageMatch[0] : "", "")
      .replace(peopleMatch ? peopleMatch[0] : "", "")
      .trim();

    return { percentage, numberOfPeople, discountGroup };
  };

  return (
    <Box>
      <Typography variant="h5" mb={2} fontWeight="bold">
        Bulk discount (by Percent)
      </Typography>
      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Discount group</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tourData.map((tour, index) => (
              <TableRow key={tour.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{tour.discountOption}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DiscountTable;
