import React from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox } from '@mui/material';
import Layout from '../../Components/Layout';
import Footer from '../../Components/Footer';


import backgroundImage from '../../Assets/All/Tour.jpg';
import Listitem from './ListItem';

const Cookies: React.FC = () => {
    return (
        <Layout>
            <Box>
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: '60vh',
                    }}
                >
                </Box>

                <Box sx={{ m: { lg: 10, xs: 3 } }}>
                    <Typography fontSize={28} mb={1} fontWeight='bold'>Privacy and Cookies Statement – TourEase.com</Typography>
                    <Typography variant='body1' >Welcome to TourEase.com, your trusted travel companion. Your privacy and security are of utmost importance to us. This Privacy and Cookies Statement outlines how we collect, use, and protect your personal information when you visit our website.</Typography>

                    <Box sx={{  mt: 5 }}>
                        <Typography fontWeight='bold' variant='h6'>1. Information We Collect:</Typography>
                        <ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Personal Information:" nestedText={'When you make a reservation or sign up for an account, we may collect personal information such as your name, email address, phone number, and payment details to facilitate your booking.'} />
</ol>
<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Log Data:" nestedText={'We collect information that your browser sends whenever you visit our website. This may include your computer’s Internet Protocol (IP) address, browser type, browser version, and the pages you visit.'} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Cookies:" nestedText={'We use cookies to enhance your browsing experience. Cookies are small pieces of data stored on your device that improve website functionality. You can choose to disable cookies through your browser settings, but this may impact certain features of our website.'} />
</ol>


<Typography fontWeight='bold' variant='h6' mt={2}>2. How We Use Your Information:</Typography>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Reservation Processing:" nestedText={'Your personal information is used to process and confirm your reservations, ensuring a seamless booking experience.'} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Communication:" nestedText={'We may use your contact details to communicate important information about your reservation, provide updates, and respond to your inquiries.'} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Improving User Experience:" nestedText={'Analyzing log data helps us understand how visitors use our website, enabling us to enhance user experience and optimize our services.'} />
</ol>

<Typography fontWeight='bold' variant='h6' mt={2}>3. Security:</Typography>
<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Data Security:" nestedText={'We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.'} />
</ol>


<Typography fontWeight='bold' variant='h6' mt={2}>4. Links:</Typography>
<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Third-Party Links:" nestedText={'Our website may contain links to third-party websites. Please note that we are not responsible for the privacy practices of these external sites. We encourage you to review the privacy statements of any website you visit through our platform.'} />
</ol>


<Typography fontWeight='bold' variant='h6' mt={2}>5. Cookie Policy:</Typography>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Types of Cookies:" nestedText={'We use session and persistent cookies to enhance your browsing experience. These cookies may be essential for the functionality of the website or used for analytics and advertising purposes.'} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Opting Out:" nestedText={'You have the option to disable cookies through your browser settings. Please note that some features of our website may not function properly if cookies are disabled.'} />
</ol>

           
<Typography fontWeight='bold' variant='h6' mt={2}>6. Change to Privacy and Cookie:</Typography>
<ol style={{ listStyleType: 'square', marginLeft: '4rem' }}>
    <Listitem text="Changes to this Privacy and Cookies Statement:" nestedText={'We reserve the right to update and modify this Privacy and Cookies Statement. Any changes will be reflected on this page, and we recommend checking this statement periodically for updates.'} />
</ol>





                    </Box>
                </Box>
            </Box>
            <Footer />
        </Layout>
    );
};

export default Cookies;
