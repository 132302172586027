import React, { useState } from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox, Rating, TextField } from '@mui/material';
import Layout from '../../../Components/Layout';
import Footer from '../../../Components/Footer';
import ImageGrid from '../../../Components/HotelData/ImageGrid';
import SearchBar from '../../../Components/HotelData/HotelBar';
import Review from '../../../Components/HotelData/Review';
import ReviewForm from '../../../Components/HotelData/ReviewForm';

import Availabilty from '../../../Components/HotelData/Availabilty';

const FamreeResortHunza: React.FC = () => {


  return (


    <Layout>
      <Box p={1}></Box>
      <Box sx={{ m: { lg: 5, xs: 3 } }}>
        <ImageGrid
          images={[require('./HotelsImages/famree_1.jpg'),
          require('./HotelsImages/famree_2.jpg'),
          require('./HotelsImages/famree_3.jpg'),
          require('./HotelsImages/famree_4.jpg'),
          require('./HotelsImages/famree_5.jpg')]}
          title="Famree Resort Hunza"
          address="Attabad lake Gojal Hunza ,famary hotel & resort Hunza, 15100 Hunza, Pakistan"
          aboutTitle="About this hotel"
          aboutText="Situated facing the beachfront, Famree Resort Hunza offers 5-star accommodation in Hunza, featuring a garden, terrace, and restaurant. The property provides room service and a 24-hour front desk, along with complimentary WiFi throughout. Guests can also enjoy beverages at the bar.

          The hotel’s units are equipped with air conditioning, a seating area, a flat-screen TV with satellite channels, a safety deposit box, and a private bathroom with a shower, free toiletries, and a hairdryer. Bed linen and towels are provided in all rooms at Famree Resort Hunza."
        />

        <Divider sx={{ mt: 5, mb: 5 }} />


      

        <Divider sx={{ mt: 5, mb: 5 }} />
       
       
        <Review
  rating="4.5"
  totalReviews={10}
  reviewItems={[
    { label: "Cleanliness" },
    { label: "Communication" },
    { label: "Check-in" },
    { label: "Accuracy" },
    { label: "Location" }
  ]}
/>

        <Divider sx={{ mt: 5, mb: 5 }} />

        <Box >
          <Typography variant='h5' mb={1} fontWeight='bold'>Availabilty:</Typography>


          <Grid container>
            <Grid item xs={12}  >

              <SearchBar />
            </Grid>
          </Grid>
          
      <Availabilty
  imageUrl={require('./HotelsImages/famree_2.jpg')}
  roomSize="22cm"
  numBeds={3}
  numChildren={1}
  numAdults={2}
/>

<Availabilty
  imageUrl={require('./HotelsImages/famree_3.jpg')}
  roomSize="22cm"
  numBeds={3}
  numChildren={1}
  numAdults={2}
/>

<Availabilty
  imageUrl={require('./HotelsImages/famree_4.jpg')}
  roomSize="22cm"
  numBeds={3}
  numChildren={1}
  numAdults={2}
/>


        </Box>


        <Divider sx={{ mt: 5, mb: 5 }} />

<ReviewForm/>
      </Box>

  

      <Footer />
    </Layout>
  );
};


const iconStyle: React.CSSProperties = {
  fontSize: 48,
};


export default FamreeResortHunza;