import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
 

interface Props {
  title: string;
  detail: string;
}

const CustomAccordion: React.FC<Props> = ({ title, detail }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  
  const detailLines = detail.split('\n');

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpansion}
      sx={{
        '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
        '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
        '&::before': {
            position: 'relative',
        },
        mb:2, 
        bgcolor:'#F1F1F1 ',
        borderRadius:5,
        p:2,
        position:'relative',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography fontWeight='bold'>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" gutterBottom>
          {detailLines.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;

