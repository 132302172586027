import React from 'react';
import { Box,  Typography } from '@mui/material';
import Layout from '../../../Components/Layout';
import Footer from '../../../Components/Footer';


import backgroundImage from '../../../Assets/All/Gilgit.png';


const Gilgit: React.FC = () => {
    return (
        <Layout>
           
            <Box >
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'up',
                        height: '80vh',

                    }}>
                     <Typography variant='h4' color={'white'} fontWeight='bold' sx={{pt:{lg:25,xs:5},pl:{lg:10,xs:2}}}>Gilgit</Typography>
                </Box>

                <Box sx={{ mb: { lg: 10, xs: 3 }, ml: { lg: 10, xs: 3 }, mr: { lg: 10, xs: 3 } , mt: { lg: 5, xs: 3 } }}>
                    <Typography fontSize={28} mb={1} fontWeight='bold'>About – TourEase.com</Typography>
                    <Typography variant='body1' mb={2} >TourEase began as a small startup with a big dream: to simplify travel to the breathtaking region of Gilgit-Baltistan. We saw the potential for this hidden gem to become a global travel destination, yet the complex logistics often deterred potential visitors.Fueled by our passion for travel and a commitment to showcasing the beauty of Gilgit-Baltistan, we set out to bridge the gap. We started by building a user-friendly platform that streamlined the booking process for various travel essentials.</Typography>
               
               

                    <Typography fontSize={28} mb={3} >Why choose TourEase.com</Typography>

                    <ul>
    <li style={{listStyleType:'square' ,marginLeft:'3rem', marginBottom:16}}> <strong> Local Expertise:</strong> We leverage our deep understanding of the region to curate personalized itineraries and provide valuable insights.</li>
    <li style={{listStyleType:'square',marginLeft:'3rem', marginBottom:16}}> <strong>Unmatched Selection:</strong> Choose from a wide range of accommodations, activities, and transportation options to create your perfect trip.</li>
    <li style={{listStyleType:'square',marginLeft:'3rem', marginBottom:16}}> <strong>Seamless Booking:</strong> Our user-friendly platform makes booking your entire trip a breeze, saving you time and effort.</li>
    <li style={{listStyleType:'square',marginLeft:'3rem', marginBottom:16}}> <strong>Exceptional Service:</strong> Our dedicated team is available 24/7 to assist you with any questions or concerns.</li>
</ul>

                </Box>
                </Box>
          
            <Footer />
        </Layout>
    );
};

export default Gilgit;
