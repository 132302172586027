import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ManIcon from '@mui/icons-material/Man';

interface HotelCardProps {
  imageUrl: string;
  roomSize?: string;
  numBeds?: number;
  numChildren?: number;
  numAdults?: number;
}

const Availabilty: React.FC<HotelCardProps> = ({ imageUrl, roomSize, numBeds, numChildren, numAdults }) => {
  return (
    <Box sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', m: { lg: 5, xs: 3 } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <img src={imageUrl} alt="Hotel" style={{ width: '100%', height: '300px' }} />
        </Grid>

        <Grid item xs={12} sm={4} display={'flex'} flexDirection={'row'} justifyContent={'space-around'} mt={'10%'}>
          <div>
            <SquareFootIcon style={{ border: '1px solid #000', padding: 2, fontSize: 48, boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)' }} />
            <Typography textAlign={'center'}>{roomSize}</Typography>
          </div>
          <div>
            <SingleBedIcon style={{ border: '1px solid #000', padding: 2, fontSize: 48, boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)' }} />
            <Typography textAlign={'center'}>{numBeds}</Typography>
          </div>
          <div>
            <ChildCareIcon style={{ border: '1px solid #000', padding: 2, fontSize: 48, boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)' }} />
            <Typography textAlign={'center'}>{numChildren}</Typography>
          </div>
          <div>
            <ManIcon style={{ border: '1px solid #000', padding: 2, fontSize: 48, boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)' }} />
            <Typography textAlign={'center'}>{numAdults}</Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={4} textAlign={'center'} mt={'10%'}>
          <Button variant="contained" size='large' sx={{ borderRadius: 10, boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.4)' }} color="primary">Show Price</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Availabilty;
