import React, { useState } from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox, Rating, TextField } from '@mui/material';
import Layout from '../../../Components/Layout';
import Footer from '../../../Components/Footer';
import ImageGrid from '../../../Components/HotelData/ImageGrid';
import SearchBar from '../../../Components/HotelData/HotelBar';
import Review from '../../../Components/HotelData/Review';
import ReviewForm from '../../../Components/HotelData/ReviewForm';

import Availabilty from '../../../Components/HotelData/Availabilty';

const KhojResortSh0igar: React.FC = () => {


  return (


    <Layout>
      <Box p={1}></Box>
      <Box sx={{ m: { lg: 5, xs: 3 } }}>
        <ImageGrid
          images={[require('./HotelsImages/khoj_1.jpg'),
          require('./HotelsImages/khoj_2.jpg'),
          require('./HotelsImages/khoj_3.jpg'),
          require('./HotelsImages/khoj_4.jpg'),
          require('./HotelsImages/khoj_5.jpg')]}
          title="Khoj Resort Shigar"
          address="Jarba Zhou Road Kothang Paeen Village, Lamsa, 16100 Shigar, Pakistan"
          aboutTitle="About this hotel"
          aboutText="Located in Shigar, Khoj Resorts offers a garden. This 4-star hotel offers room service, a 24-hour front desk and free WiFi. There is a restaurant serving American cuisine, and free private parking is available.
          At the hotel every room comes with air conditioning, a desk, a terrace with a mountain view, a private bathroom, a flat-screen TV, bed linen and towels. All units will provide guests with a wardrobe and a kettle."
        />

        <Divider sx={{ mt: 5, mb: 5 }} />


      


        <Divider sx={{ mt: 5, mb: 5 }} />
       
       
        <Review
  rating="4.5"
  totalReviews={10}
  reviewItems={[
    { label: "Cleanliness" },
    { label: "Communication" },
    { label: "Check-in" },
    { label: "Accuracy" },
    { label: "Location" }
  ]}
/>

        <Divider sx={{ mt: 5, mb: 5 }} />

        <Box >
          <Typography variant='h5' mb={1} fontWeight='bold'>Availabilty:</Typography>


          <Grid container>
            <Grid item xs={12}  >

              <SearchBar />
            </Grid>
          </Grid>
          
      <Availabilty
  imageUrl={require('./HotelsImages/khoj_2.jpg')}
  roomSize="22cm"
  numBeds={3}
  numChildren={1}
  // numAdults={2}
/>


        </Box>


        <Divider sx={{ mt: 5, mb: 5 }} />

<ReviewForm/>
      </Box>

  

      <Footer />
    </Layout>
  );
};


const iconStyle: React.CSSProperties = {
  fontSize: 48,
};


export default KhojResortSh0igar;