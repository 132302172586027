// firebase-config.ts
import { initializeApp } from "firebase/app";
import { getAnalytics, Analytics } from "firebase/analytics";
import { getAuth, Auth } from "firebase/auth";
import { getFirestore, Firestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDMKjb-L4el2xvHbcPPMcNboRzN14oE2g8",
  authDomain: "travacations-e7be2.firebaseapp.com",
  projectId: "travacations-e7be2",
  storageBucket: "travacations-e7be2.appspot.com",
  messagingSenderId: "1076337031959",
  appId: "1:1076337031959:web:40dc57430837516afc1552",
  measurementId: "G-Y7KHWNLX9T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics, Auth, and Firestore
let analytics: Analytics | undefined;
if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}

const auth: Auth = getAuth(app);
const firestore: Firestore = getFirestore(app);
const storage = getStorage(app);
// Initialize Firestore
const db = getFirestore(app);

export { app, auth, analytics, firestore, storage,db  };
