import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface CarDescriptionProps {
  imageSrc: string;
  altText: string;
  descriptionTitle: string;
  descriptionText: string;
 
}

const CarDescription: React.FC<CarDescriptionProps> = ({
  imageSrc,
  altText,
  descriptionTitle,
  descriptionText,
  
}) => {
  return (
    <Box>
        <Box display="flex" justifyContent="center" alignItems="center">
      <img
        src={imageSrc}
        alt={altText}
        style={{ width: '70%', height: '300px' }}
      />
      </Box>
      <Typography fontWeight="bold" fontSize={25}>
        {descriptionTitle}
      </Typography>
      <Typography>
        {descriptionText}
      </Typography>
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Button variant="outlined" sx={{ fontSize: 16 }}>
          <WhatsAppIcon /> Book on Whatsapp
        </Button>
      </Box>
    </Box>
  );
};

export default CarDescription;
