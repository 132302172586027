import React from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox } from '@mui/material';
import Layout from '../../Components/Layout';
import Footer from '../../Components/Footer';
import backgroundImage from '../../Assets/All/Building.png';
import Listitem from '../SupportPages/ListItem';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomAccordion from './CustomAccordion';


const Partnership: React.FC = () => {
    return (
        <Layout>
            <Box>
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom',
                        minHeight: '70vh',

                    }}>
                     <Typography variant='h4' fontWeight='bold' sx={{pt:{lg:25,xs:5},pl:{lg:10,xs:2}}}>Partnership</Typography>
                </Box>

                <Box sx={{ mb: { lg: 10, xs: 3 }, ml: { lg: 10, xs: 3 }, mr: { lg: 10, xs: 3 } , mt: { lg: 5, xs: 3 } }}>
                    <Typography fontSize={28} mb={1} fontWeight='bold'>Partnership Opportunities at TourEase.com</Typography>
                    <Typography variant='body1' mb={2} >TourEase where we believe in the power of collaboration to enhance the travel experience. Whether you own a remarkable property or offer unique tours and transportation services in Gilgit-Baltistan, we invite you to become our partner and showcase your offerings to a global audience.</Typography>



<Typography variant='h5'>How to Get Started:</Typography>
                    <ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem
        text="Global Visibility:"
        nestedText="By partnering with Easilytrip.com, your property, tours, and transportation services gain exposure to a diverse and global audience of travelers. Maximize your visibility and attract potential customers from around the world."
    />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem
        text="Easy Listing Process:"
        nestedText="Listing your property, tours, or cars with us is a breeze. Simply sign up, provide the necessary details, and watch your offerings go live on our platform. It’s a quick and straightforward process designed to save you time and effort."
    />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem
        text="Cost-Free Collaboration:"
        nestedText="Yes, you read it right! It costs nothing to list your property, tours, or cars on Easilytrip.com. Our commitment to fostering partnerships includes offering a platform where you can showcase your offerings without any upfront charges."
    />
</ol>


<Typography variant='h5' mt={6}>How to Get Started:</Typography>

<ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem
        text="Sign Up:"
        nestedText="Create an account on Easilytrip.com by signing up as a partner. It’s a simple and free process that opens the door to a world of opportunities."
    />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem
        text="List Your Property or Services:"
        nestedText="Once your account is set up, start listing your property, tours, or transportation services. Provide details, images, and any other relevant information to make your offerings stand out."
    />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem
        text="Boom! Your Offerings Are Live:"
        nestedText="After completing the listing process, your property, tours, or cars will be showcased to travelers looking to explore the beauty of Gilgit-Baltistan. Experience the benefits of increased exposure and potential bookings."
    />
</ol>



                </Box>

                <Box sx={{ m: { lg: 10, xs: 3 } }}>
    
                <CustomAccordion
    summaryText="Is it really free to list my property or services on Easilytrip.com?"
    detailsText="Absolutely! Listing on our platform is completely free. We believe in supporting local businesses and promoting the diverse offerings of Gilgit-Baltistan."
/>

<CustomAccordion
    summaryText="How do I manage reservations and inquiries from potential guests?"
    detailsText="Our user-friendly partner dashboard allows you to manage reservations and communicate with guests seamlessly. You have full control over your availability and pricing."
/>

<CustomAccordion
    summaryText="What support can I expect from TourEase.com?"
    detailsText="Our dedicated support team is here to assist you every step of the way. From the initial sign-up to ongoing assistance, we're committed to ensuring your partnership with us is smooth and successful."
/>

      </Box>



                </Box>
            <Footer />
        </Layout>
    );
};

export default Partnership;
