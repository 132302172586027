import React from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox } from '@mui/material';
import Layout from '../../Components/Layout';
import Footer from '../../Components/Footer';


import backgroundImage from '../../Assets/All/Tour.jpg';
import Listitem from './ListItem';

const Cancel: React.FC = () => {
    return (
        <Layout>
            <Box>
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: '60vh',
                    }}
                >
                </Box>

                <Box sx={{ m: { lg: 10, xs: 3 } }}>
                    <Typography fontSize={28} mb={1} fontWeight='bold'>Cancellation Policy – TourEase.com</Typography>
                    <Typography variant='body1' >We understand that plans can change, and flexibility is crucial when it comes to travel arrangements. At TourEase.com, we strive to provide a fair and transparent cancellation policy to accommodate your needs. Please review the following cancellation terms:</Typography>

                    <Box sx={{ ml: 2, mt: 5 }}>
                        <ol style={{ listStyleType: 'square' }}>
                            <Listitem text="Reservation Confirmation:" nestedText={'To secure your hotel room or book other services, a 40% advance payment is required within 24 hours of making the reservation.'} />
                        </ol>

                        <ol style={{ listStyleType: 'square' }}>
                            <Listitem text="Cancellation within 24 Hours:" nestedText="If you need to cancel your reservation within 24 hours of making the booking, you are eligible for a 100% refund. No cancellation fees will be applied, and the entire amount paid will be returned." />
                        </ol>


                        <ol style={{ listStyleType: 'square' }}>
                            <Listitem text="No-Show Policy:" nestedText="In the event of a no-show without prior cancellation, the full booking amount will be charged, and no refund will be issued." />
                        </ol>
                        <ol style={{ listStyleType: 'square' }}>
                            <Listitem text="Modification of Reservation:" nestedText="If you wish to modify your reservation, please contact our customer support team. Changes are subject to availability and any additional charges that may apply." />

                        </ol>
                        <ol style={{ listStyleType: 'square' }}>
                            <Listitem text="Refund Process:" nestedText="Refunds will be processed using the same payment method used for the initial transaction. Please allow a reasonable processing time." />

                        </ol>

                        <ol style={{ listStyleType: 'square' }}>
                            <Listitem text="Contacting Customer Support:" nestedText="For any cancellation or modification requests, please reach out to our customer support team. We are available 24/7 to assist you." />

                        </ol>
                        <ol style={{ listStyleType: 'square' }}>
                            <Listitem text="Exceptional Circumstances:" nestedText="In case of unforeseen circumstances or emergencies, we understand that exceptions may need to be considered. Please contact our customer support team, and we will do our best to assist you." />
                        </ol>




                    </Box>
                </Box>
            </Box>
            <Footer />
        </Layout>
    );
};

export default Cancel;
