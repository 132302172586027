import React from 'react';
import { Grid } from '@mui/material';
import CarCard from '../Cards/CarCard';

interface CarDataProps {
    currentPage: number;
    carsPerPage: number;
    priceFilter: number[];
}

const CarData: React.FC<CarDataProps> = ({ currentPage, carsPerPage, priceFilter }) => {
    const carData = [
        { imageSrc: require('../../Assets/Cars/landcrusier.png'), title: 'Land Cruiser', price: 1000, passenger: '6', gear: 'Auto', luggage: '4', door: '4', linkTo: '/Landcruiser' },
        { imageSrc: require('../../Assets/Cars/Prado.png'), title: 'Prado', price: 2000, passenger: '5', gear: 'Auto', luggage: '3', door: '4', linkTo: '/Prado' },
        { imageSrc: require('../../Assets/Cars/saloon.png'), title: 'Saloon', price: 3000, passenger: '4', gear: 'Manual', luggage: '2', door: '4', linkTo: '/Saloon' },
        { imageSrc: require('../../Assets/Cars/slazzer.png'), title: 'Slazzer', price: 4000, passenger: '2', gear: 'Manual', luggage: '1', door: '2', linkTo: '/Slazzer' },
        { imageSrc: require('../../Assets/Cars/Toyota-Corolla.jpg'), title: 'Toyota Corolla', price: 1020, passenger: '4', gear: 'Auto', luggage: '2', door: '4', linkTo: '/Corolla' },
        { imageSrc: require('../../Assets/Cars/Toyota-Hiace.jpg'), title: 'Toyota Hiace', price: 1800, passenger: '12', gear: 'Auto', luggage: '8', door: '4', linkTo: '/Hiace' },
        { imageSrc: require('../../Assets/Cars/Toyota-Premio.jpg'), title: 'Toyota Premio', price: 4000, passenger: '5', gear: 'Auto', luggage: '3', door: '4', linkTo: '' }
    ];

    const filteredCarData = carData.filter(car => car.price >= priceFilter[0] && car.price <= priceFilter[1]);

    const startIndex = (currentPage - 1) * carsPerPage;
    const endIndex = startIndex + carsPerPage;
    const currentCarData = filteredCarData.slice(startIndex, endIndex);

    return (
        <Grid container spacing={1} sx={{ display: 'flex', flexDirection: 'row' }}>
            {currentCarData.map((car, index) => (
                <Grid item key={index} lg={3} md={6}>
                    <CarCard
                        imageSrc={car.imageSrc}
                        title={car.title}
                        price={car.price.toString()}
                        passenger={car.passenger}
                        gear={car.gear}
                        luggage={car.luggage}
                        door={car.door}
                        linkTo={car.linkTo}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default CarData;
