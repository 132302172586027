import React, { useState, ChangeEvent, useEffect } from "react";
import { Grid, TextField, Button, Box } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const TourBar: React.FC = () => {
  const [StartedDate, setStartedDate] = useState<string>("");
  const [EndingDate, setEndingDate] = useState<string>("");
  const [numberOfGuests, setNumberOfGuests] = useState<string>("");

  // Function to format date to 'YYYY-MM-DD'
  const getCurrentDateFormatted = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Set the default date when the component mounts
  useEffect(() => {
    const currentDate = getCurrentDateFormatted();
    setStartedDate(currentDate);
    setEndingDate(currentDate);
  }, []);
  const handleLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStartedDate(e.target.value);
  };

  const handleStartedDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStartedDate(e.target.value);
  };

  const handleEndingDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEndingDate(e.target.value);
  };

  const handleNumberOfGuestsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNumberOfGuests(e.target.value);
  };

  const handleSearch = () => {
    console.log("Search button clicked");
  };

  return (
    <Box>
      <Grid
        container
        className="bg-white-100 p-1 shadow-lg border-2 justify-around"
        sx={{
          borderRadius: { lg: 30, xs: 5 },
          bgcolor: "white"
        }}
      >
        <Grid item xs={12} sm={3}>
          <div
            className="p-3 flex flex-row rounded-full hover:shadow-2xl ml-10"
            style={{ borderRadius: 30, justifyContent: "center" }}
          >
            <LocationOnIcon sx={{ mt: 2, mr: 1 }} />
            <TextField
              type="text"
              defaultValue="Where are you going?"
              onChange={handleStartedDateChange}
              label={
                <span
                  style={{ fontWeight: "bolder", color: "black", fontSize: 20 }}
                >
                  Location
                </span>
              }
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={3}>
          <div
            className="p-3 flex flex-row rounded-full hover:shadow-2xl"
            style={{ borderRadius: 30, justifyContent: "center" }}
          >
            <CalendarMonthIcon sx={{ mt: 2, mr: 1 }} />
            <TextField
              type="date"
              value={StartedDate}
              onChange={handleStartedDateChange}
              label={
                <span
                  style={{ fontWeight: "bolder", color: "black", fontSize: 20 }}
                >
                  Pick-Up
                </span>
              }
              variant="standard"
              InputProps={{
                disableUnderline: true, // This removes the underline
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div
            className="p-3 flex flex-row rounded-full hover:shadow-2xl "
            style={{ borderRadius: 30, justifyContent: "center" }}
          >
            <CalendarMonthIcon sx={{ mt: 2, mr: 1 }} />
            <TextField
              type="date"
              value={EndingDate}
              onChange={handleEndingDateChange}
              label={
                <span
                  style={{ fontWeight: "bolder", color: "black", fontSize: 20 }}
                >
                  Check Out
                </span>
              }
              variant="standard"
              InputProps={{
                disableUnderline: true, // This removes the underline
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={2} sx={{ textAlign: "center", mt: 1 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            sx={{ borderRadius: 10, pl: 4, pr: 4, pt: 2, pb: 2 }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TourBar;
