import { Divider, Typography } from '@mui/material';
import React from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import LuggageIcon from '@mui/icons-material/Luggage';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import { Link } from 'react-router-dom';
interface TourCardProps {
  imageSrc: string;
  title: string;
  price: string;
   
  passenger?: string;
  gear?: string;
  luggage?: string;
  door?: string;
  linkTo: string;
}

const CarCard: React.FC<TourCardProps> = ({
  imageSrc,
  title,
  price,
  passenger,
  gear,
  luggage,
  door,
  linkTo
}) => {
  const iconStyle = {
    border: '2px solid gray',
    padding: '1px',
    display: 'inline-block',
    fontSize: '28px',
  };
  return (
    <Link to={linkTo} style={{ textDecoration: 'none', color: 'inherit' }}>
    <div
      style={{
        width: '100%',
        maxWidth: '280px',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        marginBottom: '3rem',
      }}
    >
      <div style={{ position: 'relative' }}>
        <img
          style={{ width: '100%', height: '100%',minHeight:"200px" }}
          src={imageSrc}
          alt={title}
          className="transition-transform duration-300 transform hover:scale-110 cursor-pointer"
        />
      </div>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <h2 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>
          {title}
        </h2>
        <Divider style={{ width: '100%', color: 'black',height: '5px' }} />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <PeopleAltIcon style={iconStyle} />
            <Typography textAlign={'center'}>{passenger}</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SettingsIcon style={iconStyle} />
            <Typography textAlign={'center'}>{gear}</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <LuggageIcon style={iconStyle} />
            <Typography textAlign={'center'}>{luggage}</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TimeToLeaveIcon style={iconStyle} />
            <Typography textAlign={'center'}>{door}</Typography>
          </div>
        </div>
        <div style={{ padding: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
          <p style={{ marginBottom: '5px' }}> Price:<strong>{price} /day</strong></p>
        </div>
      </div>
    </div>
    </Link>
  );
};

export default CarCard;

