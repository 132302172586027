import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TrekCard from "../Cards/TrekCard";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../Firebase/firebase"; // Make sure this path is correct

interface TrekDataProps {
  currentPage: number;
  itemsPerPage: number;
  priceFilter: number[]; // Add price filter prop
}

const TrekData: React.FC<TrekDataProps> = ({
  currentPage,
  itemsPerPage,
  priceFilter,
}) => {
  const [trekData, setTrekData] = useState<any[]>([]);

  useEffect(() => {
    const fetchTreks = async () => {
      const querySnapshot = await getDocs(collection(firestore, "treks"));
      const treksData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTrekData(treksData);
    };

    fetchTreks();
  }, []);

  // Apply price filter
  const filteredTrekData = trekData.filter((trek) => {
    return trek.price >= priceFilter[0] && trek.price <= priceFilter[1];
  });

  // Calculate start and end index for current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <Grid container spacing={1} sx={{ display: "flex", flexDirection: "row" }}>
      {filteredTrekData.slice(startIndex, endIndex).map((trek) => (
        <Grid item key={trek.id} lg={3} md={6}>
          <TrekCard
            imageSrc={trek.images[0]}
            title={trek.name}
            price={trek.price.toString()}
            days={trek.duration}
            linkTo={`/TrekDetail/${trek.id}`} 
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TrekData;
