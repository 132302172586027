import React, { useState } from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox, Rating, TextField } from '@mui/material';
import Layout from '../../../Components/Layout';
import Footer from '../../../Components/Footer';
import ImageGrid from '../../../Components/HotelData/ImageGrid';
import SearchBar from '../../../Components/HotelData/HotelBar';
import Review from '../../../Components/HotelData/Review';
import ReviewForm from '../../../Components/HotelData/ReviewForm';

import Availabilty from '../../../Components/HotelData/Availabilty';

const TibetHotel: React.FC = () => {


  return (


    <Layout>
      <Box sx={{p:{lg:1,xs:0}}}></Box>
      <Box sx={{ m: { lg: 5, xs: 3 } }}>
        <ImageGrid
          images={[require('./HotelsImages/Tibet_1.jpg'),
          require('./HotelsImages/elite_2.jpg'),
          require('./HotelsImages/elite_3.jpg'),
          require('./HotelsImages/elite_4.jpg'),
          require('./HotelsImages/elite_5.jpg')]}
          title="Tibet Hotel"
          address="College Road Aliabad, Near DC Office, Hunza Valley, Karimabad, Gilgit-Baltistan, 54000"
          aboutTitle="About this hotel"
          aboutText="Welcome to Elites Hunza Hotel, where luxury meets the serene beauty of the Hunza Valley. Nestled amidst the majestic mountains of Northern Pakistan, Elites Hunza Hotel offers an unparalleled experience of comfort and elegance. Our hotel boasts tastefully appointed rooms, each designed to provide guests with the utmost relaxation and convenience during their stay."
        />

        <Divider sx={{ mt: 5, mb: 5 }} />


      


        <Divider sx={{ mt: 5, mb: 5 }} />
       
       
        <Review
  rating="4.5"
  totalReviews={10}
  reviewItems={[
    { label: "Cleanliness" },
    { label: "Communication" },
    { label: "Check-in" },
    { label: "Accuracy" },
    { label: "Location" }
  ]}
/>

        <Divider sx={{ mt: 5, mb: 5 }} />

        <Box >
          <Typography variant='h5' mb={1} fontWeight='bold'>Availabilty:</Typography>


          <Grid container>
            <Grid item xs={12}  >

              <SearchBar />
            </Grid>
          </Grid>
          
      <Availabilty
  imageUrl={require('./HotelsImages/elite_1.jpg')}
  roomSize="22cm"
  numBeds={3}
  numChildren={1}
  numAdults={2}
/>


        </Box>


        <Divider sx={{ mt: 5, mb: 5 }} />

<ReviewForm/>
      </Box>

  

      <Footer />
    </Layout>
  );
};


const iconStyle: React.CSSProperties = {
  fontSize: 48,
};


export default TibetHotel;