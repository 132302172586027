import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

interface FeatureItemProps {
  text: string;
  iconType: 'check' | 'close';
  iconColor?: 'inherit' | 'primary' | 'secondary' | 'action' | 'error' | 'disabled';
  
}

const FeatureItem: React.FC<FeatureItemProps> = ({ text, iconType, iconColor = 'inherit'  }) => {
  const IconComponent = iconType === 'check' ? Check : Close;

  return (

    
     <Box  display={'flex'}flexDirection={'row'}>
        <IconComponent color={iconColor} />
          <Typography variant={'body1'}> 
            {text}
          </Typography>
        </Box>
      
   
  );
};

export default FeatureItem;
