import React from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox } from '@mui/material';
import Layout from '../../Components/Layout';
import Footer from '../../Components/Footer';


import backgroundImage from '../../Assets/All/Tour.jpg';
import Listitem from './ListItem';

const Payment: React.FC = () => {
    return (
        <Layout>
            <Box>
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: '60vh',
                    }}
                >
                </Box>

                <Box sx={{ m: { lg: 10, xs: 3 } }}>
                    <Typography fontSize={28} mb={1} fontWeight='bold'>Payment Policy – TourEase.com</Typography>
                    <Typography variant='body1' >Greetings from Easilytrip.com! We are delighted to be a part of your travel plans and understand the importance of a stress-free booking experience. Our Payment Policy is meticulously designed to prioritize transparency, flexibility, and ease for every step of your journey through the enchanting landscapes of Skardu. Please delve into the comprehensive details below to ensure a seamless and memorable stay with us.</Typography>

                    <Box sx={{  mt: 5 }}>
                         
                        <ol style={{ listStyleType: 'square',marginLeft: '1rem' }}>
    <Listitem text="Cancellation Policy: Providing Clarity and Assurance" nestedText={"We understand that plans can change, and circumstances may arise that require adjustments to your reservation. Our cancellation policy is designed to provide clarity and assurance in such situations. Depending on the terms of your booking, cancellations made within a specified timeframe may incur fees. However, we strive to accommodate our guests needs to the best of our ability, so please don't hesitate to reach out if you have any concerns."} />
</ol>

<ol style={{ listStyleType: 'square',marginLeft: '1rem'}}>
    <Listitem text="Guest Support: Assistance Every Step of the Way" nestedText={"At Easilytrip.com, our commitment to exceptional customer service extends beyond the booking process. Our dedicated support team is available around the clock to assist you with any inquiries or issues you may encounter before, during, or after your stay. Whether you need assistance with your reservation, have questions about local attractions, or require help with any other aspect of your trip, we're here to help make your experience seamless and enjoyable."} />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem text="Feedback: Your Input Matters" nestedText={'We value your feedback and take it seriously as we continuously strive to enhance our services and improve the guest experience. Whether you had a fantastic stay or encountered challenges during your trip, we encourage you to share your thoughts with us. Your input helps us identify areas for improvement and ensures that we can continue to meet and exceed your expectations in the future. Thank you for choosing Easilytrip.com, and we look forward to hearing from you.'} />
</ol>







                    </Box>
                </Box>
            </Box>
            <Footer />
        </Layout>
    );
};

export default Payment;
