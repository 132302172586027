import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

interface Facility {
  name: string;
}

interface HotelFacilitiesProps {
  facilities: Facility[];
}

const CarFacilities: React.FC<HotelFacilitiesProps> = ({ facilities }) => {
  return (
    <Box>
      <Typography variant='h5' mb={1} fontWeight='bold'>Car Facilities :</Typography>
      <Grid container spacing={1} >
        {facilities.map((facility, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Typography variant="h6" gutterBottom>
            <ConstructionIcon /> {facility.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CarFacilities;