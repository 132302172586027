
import { RouteObject } from 'react-router-dom';
import Test from '../Test';
import Home from '../Screens/Home';
import Hotel from '../Screens/Hotel';
import Tour from '../Screens/Tour';
import Car from '../Screens/Car';
import Trek from '../Screens/Trek';
import CustomTour from '../Screens/CustomTour';
import Cancel from '../Screens/SupportPages/Cancel';
import Cookies from '../Screens/SupportPages/Cookies';
import Payment from '../Screens/SupportPages/Payment';
import Terms from '../Screens/SupportPages/Terms';
import AboutUs from '../Screens/Company/AboutUs';
import HelpCenter from '../Screens/Company/HelpCenter';
import TravelArticle from '../Screens/Company/TravelArticle';
import Careers from '../Screens/Company/Careers';
import Partnership from '../Screens/Contact/Partnership';
import Faq from '../Screens/Contact/Faq';
import G_in_touch from '../Screens/Contact/G_in_touch';
import HotelElite from '../Screens/InnerScreens/AllHotels/HotelElite';
import DirlehHotelSkardu from './InnerScreens/AllHotels/DirlehHotelSkardu';
import FamreeResortHunza from './InnerScreens/AllHotels/FamreeResortHunza';
import HardRockHunza from './InnerScreens/AllHotels/HotelDetail';
import KhojResortSh0igar from './InnerScreens/AllHotels/KhojResortSh0igar';
import NorthHome from './InnerScreens/AllHotels/NorthHome';
import OasisResorts from './InnerScreens/AllHotels/OasisResorts';
import TibetHotel from './InnerScreens/AllHotels/TibetHotel';
import Prado from './InnerScreens/AllCars/ToyotaPrado';
import Landcruiser from './InnerScreens/AllCars/Landcruiser';
import Corolla from './InnerScreens/AllCars/Corolla';
import Hiace from './InnerScreens/AllCars/Hiace';
import Saloon from './InnerScreens/AllCars/Saloon';
import Slazzer from './InnerScreens/AllCars/Slazer';
import TourDetail from './InnerScreens/AllTours/TourDetail';
import Gilgit from './InnerScreens/Loaction/Gilgit';
import Hunza from './InnerScreens/Loaction/Hunza';
import Skardu from './InnerScreens/Loaction/Skardu';
import Khaplu from './InnerScreens/Loaction/Khaplu';
import HotelDetail from './InnerScreens/AllHotels/HotelDetail';
import TrekDetail from './InnerScreens/AllTreks/TrekDetail';



const routes: RouteObject[] = [
  { path: '/test', element: <Test /> },
  { path: '/', element: <Home /> },
  { path: '/Hotel', element: <Hotel /> },
  { path: '/Tour', element: <Tour /> },
  { path: '/Trek', element: <Trek /> },
  { path: '/Car', element: <Car /> },
  { path: '/CustomTour', element: <CustomTour /> },
  { path: '/Cancel', element: <Cancel /> },
  { path: '/Cookies', element: <Cookies /> },
  { path: '/Payment', element: <Payment /> },
  { path: '/Term', element: <Terms /> },
  { path: '/Aboutus', element: <AboutUs /> },
  { path: '/HelpCenter', element: <HelpCenter /> },
  { path: '/TravelArticle', element: <TravelArticle /> },
  { path: '/Careers', element: <Careers /> },
  { path: '/Partnership', element: <Partnership /> },
  { path: '/Faq', element: <Faq /> },
  { path: '/InTouch', element: <G_in_touch /> },
  { path: '/HotelElite', element: <HotelElite /> },
  { path: '/DirlehHotelSkardu', element: <DirlehHotelSkardu /> },
  { path: '/FamreeResortHunza', element: <FamreeResortHunza /> },
  { path: '/HardRockHunza', element: <HardRockHunza /> },
  { path: '/KhojResortSh0igar', element: <KhojResortSh0igar /> },
  { path: '/NorthHome', element: <NorthHome /> },
  { path: '/OasisResorts', element: <OasisResorts /> },
  { path: '/TibetHotel', element: <TibetHotel /> },



  // -----cars-------
  { path: '/Prado', element: <Prado /> },
  { path: '/Corolla', element: <Corolla /> },
  { path: '/Hiace', element: <Hiace /> },
  { path: '/Landcruiser', element: <Landcruiser /> },
  { path: '/Saloon', element: <Saloon /> },
  { path: '/Slazzer', element: <Slazzer /> },


  // -----Tour-------
  { path: '/TourDetail/:id', element: <TourDetail /> },

  // -----Hotel-------
  { path: '/HotelDetail/:id', element: <HotelDetail /> },

  // -----Trek-------
  { path: '/TrekDetail/:id', element: <TrekDetail /> },
  
  // -----Destination-------

  { path: '/Gilgit', element: <Gilgit/> },
  { path: '/Hunza', element: <Hunza/> },
  { path: '/Skardu', element: <Skardu/> },
  { path: '/Khaplu', element: <Khaplu/> },


];

export default routes;
