import React, { useState } from 'react';
import { Typography, Grid, TextField, Button, Box } from '@mui/material';
import Rating from '@mui/material/Rating';

const ReviewForm: React.FC = () => {
  const [formVisible, setFormVisible] = useState(false);

  const handleButtonClick = () => {
    setFormVisible(true);
  };

  return (
    <Box>
      <Typography variant='h5' mb={2} fontWeight='bold'>Leave a review</Typography>
      {!formVisible && (
        <Button variant="contained" color="primary" onClick={handleButtonClick}>
          Write a Review
        </Button>
      )}
      {formVisible && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="name"
              label="Name"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="title"
              label="Title"
              variant="outlined"
              fullWidth
            />
          </Grid>
          
          <Grid item xs={6} sm={4}>
            <Typography variant='h6' mb={1}>Cleanliness</Typography>
            <Rating
              name="cleanliness"
              id="cleanliness"
              defaultValue={0}
              precision={0.5}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant='h6' mb={1}>Accuracy</Typography>
            <Rating
              name="accuracy"
              id="accuracy"
              defaultValue={0}
              precision={0.5}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant='h6' mb={1}>Communication</Typography>
            <Rating
              name="communication"
              id="communication"
              defaultValue={0}
              precision={0.5}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant='h6' mb={1}>Location</Typography>
            <Rating
              name="location"
              id="location"
              defaultValue={0}
              precision={0.5}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant='h6' mb={1}>Check-in</Typography>
            <Rating
              name="check-in"
              id="check-in"
              defaultValue={0}
              precision={0.5}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant='h6' mb={1}>Value</Typography>
            <Rating
              name="value"
              id="value"
              defaultValue={0}
              precision={0.5}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              required
              id="content"
              label="Content"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ReviewForm;
