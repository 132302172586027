import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface DurationBoxProps {
  icon: React.ComponentType<{ style: React.CSSProperties }>; // Use React.ComponentType to define the icon as a component
  iconColor: string;
  text: string;
  text1: string;
}

const BlueBox: React.FC<DurationBoxProps> = ({
  icon: Icon,
  iconColor,
  text,
  text1,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box p={1} bgcolor={"#42a5f5"} borderRadius={2}>
          <Icon style={{ color: iconColor }} />{" "}
          {/* Apply color dynamically using style */}
        </Box>
        <Box>
          <Typography variant={"body1"} fontWeight="bold">
            {text}
          </Typography>
          <Typography variant={"body1"}>{text1}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BlueBox;
