import React, { useState } from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox, Rating, TextField } from '@mui/material';
import Layout from '../../../Components/Layout';
import Footer from '../../../Components/Footer';
import ImageGrid from '../../../Components/HotelData/ImageGrid';
import SearchBar from '../../../Components/HotelData/HotelBar';
import Review from '../../../Components/HotelData/Review';
import ReviewForm from '../../../Components/HotelData/ReviewForm';

import Availabilty from '../../../Components/HotelData/Availabilty';

const NorthHome: React.FC = () => {


  return (


    <Layout>
      <Box p={1}></Box>
      <Box sx={{ m: { lg: 5, xs: 3 } }}>
        <ImageGrid
          images={[require('./HotelsImages/North_1.jpeg'),
          require('./HotelsImages/North_2.jpeg'),
          require('./HotelsImages/North_3.jpeg'),
          require('./HotelsImages/North_4.jpeg'),
          require('./HotelsImages/North_5.jpeg')]}
          title="North Home Guest House"
          address="
          Mirmat Chowk Hajigam Skardu"
          aboutTitle="About this hotel"
          aboutText="North Home Guest House"
        />

        <Divider sx={{ mt: 5, mb: 5 }} />




        <Divider sx={{ mt: 5, mb: 5 }} />
       
       
        <Review
  rating="4.5"
  totalReviews={10}
  reviewItems={[
    { label: "Cleanliness" },
    { label: "Communication" },
    { label: "Check-in" },
    { label: "Accuracy" },
    { label: "Location" }
  ]}
/>

        <Divider sx={{ mt: 5, mb: 5 }} />

        <Box >
          <Typography variant='h5' mb={1} fontWeight='bold'>Availabilty:</Typography>


          <Grid container>
            <Grid item xs={12}  >

              <SearchBar />
            </Grid>
          </Grid>
          
      <Availabilty
  imageUrl={require('./HotelsImages/North_1.jpeg')}
  roomSize="22cm"
  numBeds={3}
  numChildren={3}
  numAdults={2}
/>


        </Box>


        <Divider sx={{ mt: 5, mb: 5 }} />

<ReviewForm/>
      </Box>

  

      <Footer />
    </Layout>
  );
};


const iconStyle: React.CSSProperties = {
  fontSize: 48,
};


export default NorthHome;