import React, { useState } from "react";
import "./ImageGrid.css"; // Import the CSS file
import { Box, Typography, Button, Grid } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import FavoriteIcon from "@mui/icons-material/Favorite";

interface ImageProps {
  images: string[]; // Array of image URLs
  title: string; // Title of the place
  address: string; // Address of the place
  aboutTitle: string; // Title of the about section
  aboutText: string; // Text of the about section
}

const ImageGrid: React.FC<ImageProps> = ({
  images,
  title,
  address,
  aboutTitle,
  aboutText,
}) => {
  const [filled, setFilled] = useState(false);

  const handleClick = () => {
    setFilled(!filled);
  };

  return (
    <div>
      <Grid container spacing={2} sx={{ mb: 8, mt: 8 }}>
        <Grid item xs={12} md={8}>
          <div style={{ marginTop: "100px" }}>
            <Typography variant="h4" fontWeight="bold">
              {title}
            </Typography>
            <Typography>
              {" "}
              <PlaceIcon fontSize="small" />
              {address}
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ justifyContent: { xs: "center" } }}
        >
          <FavoriteIcon
            onClick={handleClick}
            sx={{
              fontSize: 28,
              color: filled ? "red" : "black",
              cursor: "pointer",
              border: "1px",
            }}
          />
          <Button variant="contained" sx={{ borderRadius: 10, ml: 2 }}>
            Reserve a Room
          </Button>
        </Grid>
      </Grid>

      <div className="image-grid">
        {images.map((image, index) => (
          <img
            key={index}
            className={`image-grid-item ${
              index === 0 ? "image-grid-col-2 image-  -row-2" : ""
            }`}
            src={image}
            alt="architecture"
          />
        ))}
      </div>
      {/* <div className="image-grid" style={{ borderRadius: 40 }}>
        <img
          className="image-grid-col-2 image-grid-row-2"
          src={images[0]}
          alt="architecture"
        />
        <img src={images[1]} alt="architecture" />
        <img src={images[2]} alt="architecture" />
        <img src={images[3]} alt="architecture" />
        <img src={images[4]} alt="architecture" />
      </div> */}

      <Typography variant="h5" mt={10} mb={1} fontWeight="bold">
        {aboutTitle}
      </Typography>
      <Typography>{aboutText}</Typography>
    </div>
  );
};

export default ImageGrid;
