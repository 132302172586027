import React from 'react';
import { Grid, Typography, Box, Divider } from '@mui/material';
import { Twitter, Facebook, Instagram, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box sx={{ pt: 5, pb: 5, pl: 10, pr: 10, bgcolor: '#74264c',}}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <div className="footer-menu">
            <Typography variant="h5" fontWeight={'bold'} gutterBottom style={{ color:"#e94d50" }}> Support</Typography>
            <ul className="footer-menu-list">
              <li style={{ marginBottom: '8px',color:"white" }}><a href="/Cancel" >Cancellation Policy</a></li>
              <li style={{ marginBottom: '8px',color:"white" }}><a  href="/Cookies">Cookies Statement</a></li>
              <li style={{ marginBottom: '8px',color:"white" }}><a  href="/Payment">Payment Policy</a></li>
              <li style={{ marginBottom: '8px',color:"white" }}><a  href="/Term">Terms and Conditions</a></li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className="footer-menu">
            <Typography variant="h5" fontWeight={'bold'}gutterBottom style={{ color:"#e94d50" }}> Company</Typography>
            <ul className="footer-menu-list">
              <li style={{ marginBottom: '8px',color:"white" }}><a href="/Aboutus">About us</a></li>
              <li style={{ marginBottom: '8px',color:"white" }}><a href="/HelpCenter">Help Center</a></li>
              <li style={{ marginBottom: '8px',color:"white" }}><a href="/TravelArticle">Travel Articles</a></li>
              <li style={{ marginBottom: '8px',color:"white" }}><a href="/Careers">Careers</a></li>
          
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <div className="footer-menu">
            <Typography variant="h5"fontWeight={'bold'} gutterBottom style={{ color:"#e94d50" }}> Contact</Typography>
            <ul className="footer-menu-list">
              <li style={{ marginBottom: '8px',color:"white" }}><a href="/Partnership">Partnerships</a></li>
              <li style={{ marginBottom: '8px',color:"white" }}><a href="/Faq">FAQ</a></li>
              <li style={{ marginBottom: '8px',color:"white" }}><a href="/InTouch">Get in touch</a></li>
              <li style={{ marginBottom: '8px',color:"white" }}><a href="/InTouch">Contact Us</a></li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <div className="footer-call-to-action">
            <Typography variant="h5" fontWeight={'bold'} gutterBottom style={{ color:"#e94d50" }}> Let's Chat</Typography>
            <div className="footer-call-to-action-social-icons mt-2 ">
            <a href="https://www.facebook.com/travacationspakistan" target="_blank"  className='mr-3'>
             <Facebook style={{ color:"white" }} className="social-icon" />
            </a>
              <a href="https://instagram.com/travacationspakistan" target="_blank" rel="noopener noreferrer">
                <Instagram style={{ color:"white" }} className="social-icon" />
              </a> 
            </div>
            <div className='mt-3'>
              <Typography variant="h6" gutterBottom style={{ color:"#e94d50" }}> Payment Method</Typography>
              <img src={require("../Assets/All/payment.png")} alt="Payment Method 1" className="payment-method-image" />
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 3 }} />
      <Box sx={{ display: 'flex', flexDirection:{xs:'column'}}}>
        <Typography variant="h6" fontSize={24} fontWeight='bold' style={{ color:"#e94d50" }}  >Travacations Pakistan</Typography>
        <Typography mt={1} ml={2} style={{ color:"white" }}>© travacationspakistan.com® Pvt Limited. All rights reserved.</Typography>
      </Box>
    </Box>
  );
}

export default Footer;

