
import React, { useState } from 'react';
import { Box, Typography, Grid, LinearProgress } from '@mui/material';
 

interface ReviewItem {
  label: string;
}

interface ReviewSectionProps {
  rating: string;
  totalReviews: number;
  reviewItems: ReviewItem[];
}

const Review: React.FC<ReviewSectionProps> = ({ rating, totalReviews, reviewItems }) => {
  const [progressValues, setProgressValues] = useState<number[]>(new Array(reviewItems.length).fill(0));

  const handleClick = (index: number) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = (event.currentTarget as HTMLDivElement).getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const barWidth = rect.width;
    const newValue = (clickX / barWidth) * 100; // calculate the new value based on click position
    const newProgressValues = [...progressValues];
    newProgressValues[index] = newValue;
    setProgressValues(newProgressValues);
  };

  return (
    <Box>
      <Typography variant='h5' mb={1} fontWeight='bold'>Reviews:</Typography>
      <Box bgcolor={'#f5f5f5'} sx={{ p: { lg: 5, xs: 2 }, borderRadius: 10 }}>
        <Typography variant='h5' mb={3} fontWeight='bold'>{rating}/5 Not Rated ({totalReviews} review{totalReviews !== 1 ? 's' : ''})</Typography>
        <Grid container spacing={1} >
          {reviewItems.map((item, index) => (
            <Grid item xs={12} sm={6} key={index} >
 
              <Typography variant="body1" gutterBottom>
                {item.label}
              </Typography>
             
               
              <LinearProgress
                style={{ cursor: 'pointer' }}
                onClick={handleClick(index)}
                sx={{ borderRadius: 10, p: 0.5 }}
                variant="determinate"
                value={progressValues[index]}
              />
            </Grid>
            
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Review;
