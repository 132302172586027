import React from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox } from '@mui/material';
import Layout from '../../Components/Layout';
import Footer from '../../Components/Footer';


import backgroundImage from '../../Assets/All/Building.png';


const TravelArticle: React.FC = () => {
    return (
        <Layout>
            <Box>
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom',
                        minHeight: '70vh',

                    }}>
                     <Typography variant='h4' fontWeight='bold' sx={{pt:{lg:25,xs:5},pl:{lg:10,xs:2}}}> About Us</Typography>
                </Box>

                <Box sx={{ m: { lg: 10, xs: 3 } }}>
                    <Typography fontSize={28} mb={1} fontWeight='bold'>Cancellation Policy – TourEase.com</Typography>
                    <Typography variant='body1' >We understand that plans can change, and flexibility is crucial when it comes to travel arrangements. At TourEase.com, we strive to provide a fair and transparent cancellation policy to accommodate your needs. Please review the following cancellation terms:</Typography>
                </Box>
               
               
               
                </Box>
          
            <Footer />
        </Layout>
    );
};

export default TravelArticle;