import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
interface CardProps {
  imageSrc: string;
  title: string;
  carTitle: string;
  hotelTitle: string;
  tourTitle: string;
  linkTo: string;
}

const LocationCard: React.FC<CardProps> = ({ imageSrc, title, carTitle, hotelTitle, tourTitle ,linkTo}) => {
  return (
    <Link to={linkTo} style={{ textDecoration: 'none', color: 'inherit' }}>
    <Box>
      <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl px-6 pb-8 pt-28 max-w-sm mx-8 mt-16">
        <img src={imageSrc} alt={title} className="absolute inset-0 h-full w-full object-cover " />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>
        <Typography className="z-10 mb-5 text-center text-2xl font-bold text-white" variant='h4'>{title}</Typography>
        <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 text-white mt-6" style={{display:'flex',justifyContent:'space-between'}}>
          <Typography>{carTitle}</Typography>
          <Typography>{hotelTitle}</Typography>
          <Typography>{tourTitle}</Typography>
        </div>
      </article>
    </Box>
    </Link>
  );
};

export default LocationCard;
