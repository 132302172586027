import React, { ReactNode } from 'react';
import Topbar from './Topbar';
import { FloatingWhatsApp } from 'react-floating-whatsapp2';
import ScrollToTop from "react-scroll-to-top";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Topbar />
      {children}
      <FloatingWhatsApp
    phoneNumber="+923008640889"
    accountName="Tour Ease"
    avatar={require('../Assets/All/fav.png')}
    chatMessage='Hello from Tour Ease! 🤝 How can we assist you today'
  
  />
   <ScrollToTop color='#08669c' width='32' viewBox="-1 0 180 256"  style={{ marginRight: 70 }} />
    </>
  );
}

export default Layout;

