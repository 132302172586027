import React, { useState } from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, Paper, FormControlLabel, Checkbox, Rating, TextField } from '@mui/material';
import Layout from '../../../Components/Layout';
import Footer from '../../../Components/Footer';
import ReviewForm from '../../../Components/HotelData/ReviewForm';
import CarBar from '../../../Components/CarData/CarBar';
import CarFacilities from '../../../Components/CarData/CarFaciliites';
import Review from '../../../Components/HotelData/Review';
import RatingStars from '../../../Components/CarData/RatingStars';
import FeatureItem from '../../../Components/CarData/FeatureItem';
import CarDescription from '../../../Components/CarData/CarDescription';

const Slazer: React.FC = () => {

 
  
    return (


        <Layout>
            <Box sx={{ p: { lg: 1, xs: 0 } }}></Box>
            <Box sx={{ m: { lg: 10, xs: 3 } }}>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
 

<RatingStars title="Slazzer" initialRating={0} reviewCount={0} />

  <Divider sx={{ mt: 5, mb: 5 }} />

  <Box display="flex" alignItems="center" justifyContent="space-between">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FeatureItem text="Free Cancellation" iconType="check" iconColor="primary" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FeatureItem text="Pay at Pickup" iconType="close" iconColor="error" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FeatureItem text="Unlimited Mileage" iconType="check" iconColor="primary" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FeatureItem text="Shuttle to Car" iconType="close" iconColor="error" />
        </Grid>
       
      </Grid>
    </Box>

 
    <Divider sx={{ mt: 5, mb: 5 }} />

    <Box>
      <CarDescription
        imageSrc={require('./Cars/slazzer.png')}
        altText="Slazzer"
        descriptionTitle="Description"
        descriptionText="Slazzer"
   
      />
 
    </Box>
               
                        <Divider sx={{ mt: 5, mb: 5 }} />
                        <Box>
                            <CarFacilities facilities={[
                                { name: "AirBag" },
                                { name: "FM Radio" },
                                { name: "Power Windows" },
                                { name: "Sensor" },
                                { name: "Speed" },
                                { name: "Steering Wheel" },

                            ]} />
                        </Box>
                        <Divider sx={{ mt: 5, mb: 5 }} />
                              <Box sx={{mt:5}}>
                    <Typography variant='h5' mb={1} fontWeight='bold'>Availabilty:</Typography>
                 

                            <CarBar />
                   
                </Box>
                <Divider sx={{ mt: 5, mb: 5 }} />


                        <Box>
                           <Review 
                             rating="4.5"
                             totalReviews={10}
                             reviewItems={[
                               { label: "Excellent" },
                               { label: "Good" },
                               { label: "Average" },
                               { label: "Poor" },
                               { label: "Terrible" }
                             ]}/>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={4}>

              
                    </Grid>
                </Grid>




                <Divider sx={{ mt: 5, mb: 5 }} />

                <ReviewForm />
            </Box>



            <Footer />
        </Layout>
    );
};


const iconStyle: React.CSSProperties = {
    fontSize: 48,
};


export default Slazer;