import React from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox } from '@mui/material';
import Layout from '../../Components/Layout';
import Footer from '../../Components/Footer';
import Listitem from '../SupportPages/ListItem';


import backgroundImage from '../../Assets/All/Building.png';


const Careers: React.FC = () => {
    return (
        <Layout>
            <Box>
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom',
                        minHeight: '70vh',

                    }}>
                    <Typography variant='h4' fontWeight='bold' sx={{ pt: { lg: 25, xs: 5 }, pl: { lg: 10, xs: 2 } }}>Careers</Typography>
                </Box>

                <Box sx={{ m: { lg: 10, xs: 3 }, bgcolor: 'blue', p: { lg: 6, xs: 2 }, }}>
                    <Typography fontSize={38} mb={1} color={'white'} textAlign={'center'} fontWeight='bold'>No Career Opportunities Available Currently</Typography>
                    <Typography variant='body1' color={'white'} textAlign={'center'}  >Thank you for your interest in exploring career opportunities at Easilytrip.com. As of now, we do not have any specific job openings. However, we appreciate your enthusiasm and encourage you to check back periodically for updates on potential opportunities that align with your skills and career aspirations.</Typography>
                </Box>


                <Box sx={{ m: { lg: 10, xs: 3 } }}>
                    <Typography fontSize={24} mb={1} fontWeight='bold'>Why Keep an Eye on TourEase</Typography>

                    <ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
                        <Listitem
                            text="Dynamic Environment:"
                            nestedText="We believe in fostering a dynamic work environment that values innovation, collaboration, and individual growth. Future opportunities may arise that align with your professional goals."
                        />
                    </ol>

                    <ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
                        <Listitem
                            text="Growth Potential:"
                            nestedText="Our commitment to excellence extends to our team members’ growth. Stay connected for updates on potential roles that offer opportunities for advancement and development."
                        />
                    </ol>

                    <ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
                        <Listitem
                            text="Inclusive Culture:"
                            nestedText="At TourEase.com, we embrace diversity and inclusivity. When opportunities become available, we encourage applications from individuals with diverse backgrounds and skill sets."
                        />
                    </ol>

                </Box>

            </Box>

            <Footer />
        </Layout>
    );
};

export default Careers;
