import React from 'react';
import { Box, Grid, Typography, Slider, Button, Divider, FormControlLabel, Checkbox,Paper } from '@mui/material';
import Layout from '../../Components/Layout';
import Footer from '../../Components/Footer';
import backgroundImage from '../../Assets/All/Building.png';
import Listitem from '../SupportPages/ListItem';
import { TextField  } from '@mui/material';


const HelpCenter: React.FC = () => {
    return (
        <Layout>
            <Box>
                <Box
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom',
                        minHeight: '70vh',

                    }}>
                    <Typography variant='h4' fontWeight='bold' sx={{ pt: { lg: 25, xs: 5 }, pl: { lg: 10, xs: 2 } }}>Help Center</Typography>
                </Box>

                <Box sx={{ m: { lg: 10, xs: 3 } }}>
                    <Typography fontSize={28} mb={1} fontWeight='bold'>Welcome to TourEase.com Help Center</Typography>
                    <Typography variant='body1' mb={3}>we’re dedicated to making your travel experience to Skardu and its surrounding areas as smooth as the mountain breeze. Whether you’re looking for the perfect hotel, planning a trekking adventure, or in need of reliable transportation, our Help Center is here to guide you every step of the way.</Typography>



                    <Typography fontSize={28} mb={1} fontWeight='bold'>Welcome to TourEase.com Help Center</Typography>
                    <ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem text="How do I book a hotel on TourEase.com?" nestedText="To book a hotel, simply enter your travel dates and preferences on our homepage. Browse through the list of carefully curated accommodations, select the one that suits your needs, and proceed to secure booking." />
</ol>


                    <ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem text="What tour services do you offer?" nestedText="We provide a range of tour services, including trekking, hiking, and more. Explore our Tours section to find the perfect adventure for your stay in Skardu." />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem text="Can I book a complete travel package, including hotel, tour, and transportation?" nestedText="Absolutely! With our integrated platform, you can book hotels, tours, and car rentals all in one go. Customize your entire travel experience effortlessly." />
</ol>

<ol style={{ listStyleType: 'square', marginLeft: '1rem' }}>
    <Listitem text="How do I modify or cancel my reservation?" nestedText="For reservation modifications or cancellations, log in to your account, go to 'My Bookings,' and follow the simple steps. Be sure to check the cancellation policy for each booking." />
</ol>

                </Box>



                <Box sx={{ m: { lg: 10, xs: 3 } }}>
                    <Typography fontSize={28} mb={1} fontWeight='bold'>Not Helpful?</Typography>
                   
                    <Paper elevation={3} sx={{padding:3 ,borderRadius:5}}>
            <Typography fontSize={28} mb={4} mt={2}  fontWeight='bold'>Send a message</Typography>

            <Grid container spacing={2}>
                <Grid item  xs={12} sm={6} sx={{mb:2}}>
                    <TextField
                        required
                        fullWidth
                        label="Your name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6} sx={{mb:2}}>
                    <TextField
                        required
                        fullWidth
                        label="Phone number"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6} sx={{mb:2}}>
                    <TextField
                        required
                        fullWidth
                        label="Your email"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sx={{mb:2}}>
                    <TextField
                        required
                        fullWidth
                        label="Message"
                        multiline
                        rows={4}
                        variant="outlined"
                    />
                </Grid>
                <Grid item lg={2} xs={12}>
                    <Button variant="contained" color="primary" fullWidth>
                        Send Message
                    </Button>
                </Grid>
            </Grid>
        </Paper>

                </Box>



            </Box>

            <Footer />
        </Layout>
    );
};

export default HelpCenter;