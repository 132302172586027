import { Divider } from '@mui/material';
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarIcon from '@mui/icons-material/Star';
import { Link } from 'react-router-dom';

interface TourCardProps {
  imageSrc: string;
  title: string;
  price: string;
  days?: string; // Make days prop optional
  rating?: number; // Add rating prop
  linkTo: string;
}

const HotelCard: React.FC<TourCardProps> = ({ imageSrc, title, price, days, rating, linkTo }) => {
  const renderRatingStars = () => {
    if (rating === undefined) return null;

    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<StarIcon key={i} style={{ color: 'orange', fontSize: '1.2rem' }} />);
      } else {
        stars.push(<StarIcon key={i} style={{ color: 'white', fontSize: '1.2rem' }} />);
      }
    }
    return stars;
  };

  return (
    <Link to={linkTo} style={{ textDecoration: 'none', color: 'inherit' }}>
    <div style={{ width: '100%', maxWidth: '280px', borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: 'white', marginBottom: '3rem' }}>
      <div style={{ position: 'relative' }}>
        <img
          style={{ width: '100%', height: '100%',objectFit:"cover" ,minHeight:"200px"}}
          src={imageSrc}
          alt={title}
          className="transition-transform duration-300 transform hover:scale-110 cursor-pointer"
        />
      </div>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <h2 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>{title}</h2>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          {renderRatingStars()}
        </div>
        <Divider style={{ width: '100%', color: 'black', height: '5px' }} />
        <div style={{ padding: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
          <p style={{ marginBottom: '5px' }}> Price: <strong>{price}</strong></p>
          {days && <p style={{ marginBottom: '5px' }}> <strong> <AccessTimeIcon sx={{ fontSize: 16 }} />{days}</strong></p>}
        </div>
     
      </div>
    </div>
    </Link>
  );
};

export default HotelCard;
