import React, { useState } from "react";
import Layout from "../Components/Layout";
import Footer from "../Components/Footer";
import { WhatsApp } from "@mui/icons-material";
import {
  Typography,
  Paper,
  Box,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

const CustomTour: React.FC = () => {
  return (
    <Layout>
      <Box>
        <Box
          sx={{
            backgroundImage: `url(${require("../Assets/All/Home_image1.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "25rem",
            p: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  sx={{ color: "white" }}
                >
                  Craft Your Dream Escape
                </Typography>
              </Grid>
              <Grid item lg={12}>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Design an unforgettable experience tailored to your interests
                  and desires
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" sx={{ bgcolor: "#FFC000" }}>
                  {" "}
                  <WhatsApp />
                  Submit Request
                </Button>
              </Grid>
            </Grid>
          </div>
        </Box>

        <Box sx={{ m: { lg: 10, xs: 2 } }}>
          <Typography fontSize={20} fontWeight="bold">
            Welcome to our custom tour request page! If you have a specific
            destination or itinerary in mind that you couldn't find on our
            regular booking options, we're here to help you plan your dream
            adventure.
          </Typography>

          <Typography fontSize={18} fontWeight="bold" mt={2}>
            How it works:
          </Typography>
          <Box sx={{ m: { lg: 2, xs: 1 } }}>
            <Typography fontSize={18} mb={1}>
              <strong>1: Fill out the form below: </strong>Provide us with
              details about your desired destination, duration, preferred
              activities, and any special requirements you may have.
            </Typography>
            <Typography fontSize={18} mb={1}>
              <strong>2: We’ll get in touch: </strong>Once we receive your
              request, our experienced team will review your requirements and
              get in touch with you to discuss the details further.
            </Typography>
            <Typography fontSize={18} mb={2}>
              <strong>3: Personalized itinerary: </strong> Based on your
              preferences and requirements, we’ll create a personalized
              itinerary just for you.
            </Typography>
            <Typography fontSize={18} mb={1}>
              <strong>4: Confirmation: </strong>Once you’re satisfied with the
              proposed itinerary, we’ll finalize the details and confirm your
              custom tour booking.
            </Typography>
          </Box>
        </Box>

        <Box bgcolor={"#e1ebee"} pt={2} pb={3}>
          <Typography
            textAlign={"center"}
            variant="h5"
            fontWeight="bold"
            sx={{ mt: 2 }}
          >
            Please let us know your tour preferences
          </Typography>
          <Paper
            elevation={3}
            style={{ padding: "20px" }}
            sx={{ ml: { lg: 25, xs: 3 }, mr: { lg: 25, xs: 3 }, mt: 7 }}
          >
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="WhatsApp Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Departure Country/City"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Duration of Tour"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Preferred Dates"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Tour Destination"
                    select
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="destination1">Destination 1</MenuItem>
                    <MenuItem value="destination2">Destination 2</MenuItem>
                    <MenuItem value="destination3">Destination 3</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Number of Travelers"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Trip Type"
                    select
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="type1">Type 1</MenuItem>
                    <MenuItem value="type2">Type 2</MenuItem>
                    <MenuItem value="type3">Type 3</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight="bold" fontSize={20}>
                    Meals
                  </Typography>
                  <FormControl component="fieldset" margin="normal">
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Breakfast"
                      />
                      <FormControlLabel control={<Checkbox />} label="Lunch" />
                      <FormControlLabel control={<Checkbox />} label="Dinner" />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Special Interests or Requirements"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Send Request
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </Box>

      <Footer />
    </Layout>
  );
};

export default CustomTour;
